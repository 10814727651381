import { makeAutoObservable, runInAction } from 'mobx';
import { UserReportNewService } from 'services';

class UserReportNewStoreMobx {
  createdAtStart = undefined;

  createdAtEnd = undefined;

  lastAccessStart = undefined;

  lastAccessEnd = undefined;

  emailType = undefined; // undefined = all; 0 = without; 1 = with

  birthYearStart = undefined;

  birthYearEnd = undefined;

  gender = undefined;

  language = undefined;

  os = undefined;

  country = '';

  state = undefined;

  city = undefined;

  initialized = false;

  initializeError = undefined;

  loading = false;

  filterOptions = undefined;

  // count
  // count30
  // count90
  // emailsType: emailTypeId, name
  // genders: genderId, name
  // languages: languages, name
  // oss: osId, name
  // countries: country, name
  // ufs: uf, name
  // cities: brCityId, uf, name

  count = undefined;

  data = undefined;

  constructor() {
    makeAutoObservable(this);
    this.setEmptyState();
    this.getFilters();
  }

  setEmptyState = () => {
    this.initializeError = undefined;
    this.createdAtStart = null;
    this.createdAtEnd = null;
    this.lastAccessStart = null;
    this.lastAccessEnd = null;
    this.emailType = '';
    this.birthYearStart = undefined;
    this.birthYearEnd = undefined;
    this.gender = '';
    this.language = '';
    this.os = '';
    this.country = '';
    this.state = '';
    this.city = 0;
    this.count = undefined;
    this.data = undefined;
  };

  getFilters = async () => {
    this.initialized = false;
    this.initializeError = undefined;

    try {
      const filters = await UserReportNewService.getFilters();

      runInAction(() => {
        this.filterOptions = filters;
        this.initialized = true;
      });
    } catch (e) {
      runInAction(() => {
        this.initializeError = e.message;
        this.initialized = false;
      });
    }
  };

  getReport = async (onlyCount) => {
    this.loading = true;

    try {
      const { count, data } = await UserReportNewService.getReport({
        createdAtStart: this.createdAtStart?.format('YYYY-MM-DD'),
        createdAtEnd: this.createdAtEnd
          ? `${this.createdAtEnd?.format('YYYY-MM-DD')}T23:59:59`
          : undefined,
        lastAccessStart: this.lastAccessStart
          ? this.lastAccessStart.format('YYYY-MM')
          : undefined,
        lastAccessEnd: this.lastAccessEnd
          ? `${this.lastAccessEnd.format('YYYY-MM-DD')}T23:59:59`
          : undefined,
        emailType: onlyCount ? this.emailType : '1',
        birthYearStart: this.birthYearStart
          ? parseInt(this.birthYearStart.format('YYYY'), 10)
          : undefined,
        birthYearEnd: this.birthYearEnd
          ? parseInt(this.birthYearEnd.format('YYYY'), 10)
          : undefined,
        gender: this.gender,
        language: this.language,
        os: this.os,
        country: this.country,
        state: this.state,
        city: this.city,
        reportType: onlyCount ? 'count' : 'data'
      });

      runInAction(() => {
        this.count = count;
        this.data = data;
        this.loading = false;
      });
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  setCreatedAtStart = (value) => {
    this.createdAtStart = value;
    this.count = undefined;
    this.data = undefined;
  };

  setCreatedAtEnd = (value) => {
    this.createdAtEnd = value;
    this.count = undefined;
    this.data = undefined;
  };

  setLastAccessStart = (value) => {
    this.lastAccessStart = value;
    this.count = undefined;
    this.data = undefined;
  };

  setLastAccessEnd = (value) => {
    this.lastAccessEnd = value;
    this.count = undefined;
    this.data = undefined;
  };

  setEmailType = (value) => {
    this.emailType = value;
    this.count = undefined;
    this.data = undefined;
  };

  setBirthYearStart = (value) => {
    this.birthYearStart = value;
    this.count = undefined;
    this.data = undefined;
  };

  setBirthYearEnd = (subjectId) => {
    this.birthYearEnd = subjectId;
    this.count = undefined;
    this.data = undefined;
  };

  setGender = (sponsorId) => {
    this.gender = sponsorId;
    this.count = undefined;
    this.data = undefined;
  };

  setLanguage = (value) => {
    this.language = value;
    this.count = undefined;
    this.data = undefined;
  };

  setOs = (value) => {
    this.os = value;
    this.count = undefined;
    this.data = undefined;
  };

  setCountry = (value) => {
    this.country = value;
    this.state = '';
    this.city = 0;
    this.count = undefined;
    this.data = undefined;
  };

  setState = (value) => {
    this.state = value;
    this.city = 0;
    this.count = undefined;
    this.data = undefined;
  };

  setCity = (value) => {
    this.city = value;
    this.count = undefined;
    this.data = undefined;
  };

  get citiesByState() {
    if (!this.filterOptions?.cities) {
      return [];
    }

    if (this.country !== 'BR' || !this.state) {
      return [this.filterOptions.cities[0]];
    }

    const result = this.filterOptions.cities.filter(
      (city) => city.brCityId === 0 || city.uf === this.state
    );

    return result;
  }
}

let singleton = null;

export const UserReportNewStore = () => {
  if (!singleton) {
    singleton = new UserReportNewStoreMobx();
  }
  return singleton;
};
