import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { observer } from 'mobx-react';
import appLogo from 'assets/app-logo-black.png';

import { Form, Input, Button } from 'antd';
import { UserStore } from 'stores';
import { getCSRFToken } from 'services';
import packageInfo from '../../../package.json';
import { RECAPTCHA_SITE_KEY } from '../../.env';

import './styles.css';

const LoginErrorMessages = {
  invalidCredentials: 'Nome de usuário ou senha inválidos.',
  accountNotFound: 'Não foi possível realizar o login com os dados informados.',
  unexpectedError:
    'Ocorreu um erro ao realizar o login com os dados informados. Tente novamente.'
};

const mapLoginErrors = {
  401: LoginErrorMessages.invalidCredentials,
  404: LoginErrorMessages.accountNotFound
};

const Login = observer(() => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const recaptchaRef = useRef();

  useEffect(() => {
    getCSRFToken();
  }, []);

  const handleLogin = async (email, pwd) => {
    try {
      setLoading(true);
      setHasErrors(false);
      setErrorMessage(null);
      const captcha = await recaptchaRef.current.executeAsync();
      if (!captcha) {
        setHasErrors(true);
        setErrorMessage(LoginErrorMessages.unexpectedError);
        setLoading(false);
        return;
      }

      await UserStore().login({
        email,
        pwd,
        captcha
      });
    } catch (error) {
      const message =
        mapLoginErrors[error?.status] || LoginErrorMessages.unexpectedError;
      setHasErrors(true);
      setErrorMessage(message);
      setLoading(false);
    }
  };

  return (
    <>
      <section className="login--wrapper">
        <div className="login--form anime--left">
          <img
            style={{ marginBottom: '32px' }}
            src={appLogo}
            alt="AppNews Jovem Pan"
          />
          <Form
            name="Usuário"
            onFinish={() => handleLogin(username, password)}
            layout="vertical"
          >
            <Form.Item
              label="Usuário"
              name="username"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              rules={[
                {
                  required: true,
                  message: 'Por favor insira seu nome de usuário'
                }
              ]}
            >
              <Input placeholder="Nome de usuário" disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="Password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              rules={[
                { required: true, message: 'Por favor insira sua senha' }
              ]}
              style={{ marginBottom: '36px' }}
            >
              <Input.Password placeholder="Senha" disabled={loading} />
            </Form.Item>

            <Form.Item style={{ marginBottom: '20px' }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={loading}
              >
                Acessar
              </Button>
            </Form.Item>

            {hasErrors && errorMessage && (
              <div className="login--error">
                <p>{errorMessage}</p>
              </div>
            )}

            <div className="login--version">
              <p>
                versão <span>{packageInfo.adminVersion}</span>
              </p>
            </div>
          </Form>
        </div>
      </section>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={RECAPTCHA_SITE_KEY}
      />
    </>
  );
});

export default Login;
