import { SentPushReportListRequest, SentPushReportListResponse } from 'models';
import { Network, ServiceError } from './commons';

export const SentPushReportService = {
  getReport: async ({
    language,
    subjectId,
    createdAtStart,
    createdAtEnd,
    excluded
  }: SentPushReportListRequest) => {
    try {
      const { data } = await Network.get<SentPushReportListResponse>(
        'admin/reports/pushes/sent',
        {
          params: {
            language,
            subjectId,
            createdAtStart,
            createdAtEnd,
            excluded
          }
        }
      );

      return data;
    } catch (e) {
      throw new ServiceError('news.index', e);
    }
  }
};
