import {
  TvBroadcastListResponse,
  TvBroadcastCreateRequest,
  TvBroadcastUpdateRequest,
  TvBroadcastDeleteRequest
} from 'models';
import { Network, ServiceError } from './commons';

export const TvBroadcastService = {
  list: async () => {
    try {
      const { data } = await Network.get<TvBroadcastListResponse>(
        'admin/tv_config/broadcasts'
      );
      return data?.data;
    } catch (e) {
      throw new ServiceError('tvBroadcast.list', e);
    }
  },
  create: async (data: TvBroadcastCreateRequest) => {
    try {
      await Network.post('admin/tv_config/broadcasts', data);
    } catch (e) {
      throw new ServiceError('tvBroadcast.create', e);
    }
  },
  update: async (data: TvBroadcastUpdateRequest) => {
    try {
      await Network.put(`admin/tv_config/broadcasts/${data.id}`, data);
    } catch (e) {
      throw new ServiceError('tvBroadcast.update', e);
    }
  },
  remove: async ({ id }: TvBroadcastDeleteRequest) => {
    try {
      await Network.delete(`admin/tv_config/broadcasts/${id}`);
    } catch (e) {
      throw new ServiceError('tvBroadcast.remove', e);
    }
  }
};
