import { makeAutoObservable } from 'mobx';
import { TvBroadcastService } from 'services';
import type {
  ITvBroadcast,
  TvBroadcastCreateRequest,
  TvBroadcastUpdateRequest,
  TvBroadcastDeleteRequest
} from 'models';

class TvBroadcastStoreMobx {
  broadcasts: ITvBroadcast[] = [];
  initialized = false;
  loading = true;
  error = false;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  list = async () => {
    try {
      const data = await TvBroadcastService.list();
      this.setBroadcasts(data);
      this.setLoading(false);
    } catch (e) {
      console.error('falha ao listar', e);
    }
  };

  create = async (data: TvBroadcastCreateRequest) => {
    await TvBroadcastService.create(data);
    await this.reset();
  };

  update = async (data: TvBroadcastUpdateRequest) => {
    await TvBroadcastService.update(data);
    await this.reset();
  };

  remove = async ({ id }: TvBroadcastDeleteRequest) => {
    await TvBroadcastService.remove({ id });
    await this.reset();
  };

  setBroadcasts = (data: any) => {
    this.broadcasts = data;
  };

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  reset = async () => {
    try {
      this.loading = true;
      this.error = false;
      this.broadcasts = [];
      this.initialized = true;
      this.list();
    } catch (e) {
      this.loading = false;
      this.error = true;
      console.log(e);
    }
  };
}

let singleton: TvBroadcastStoreMobx | null = null;

export const TvBroadcastStore = () => {
  if (!singleton) {
    singleton = new TvBroadcastStoreMobx();
  }
  return singleton;
};
