/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { Button, Input, message } from 'antd';
import { ArrowLeftOutlined as ArrowLeftIcon } from '@ant-design/icons';
import { RSSStore } from 'stores';

import Loading from '../../components/Loading';

export const RSSPage = observer(() => {
  const store = RSSStore();
  const {
    loading,
    configs,
    selectedConfig,
    selectedWeekDay,
    selectedWeekDayNumber
  } = store;
  const navigate = useNavigate();

  useEffect(() => {
    store.reset();
  }, [store]);

  return (
    <div style={{ margin: 32 }}>
      <span>
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: 32
          }}
          type="primary"
          size="large"
          loading={store.loading}
          disabled={store.loading || !store.isChanged}
          onClick={async () => {
            try {
              await store.save();
              message.success('Configurações do RSS salvas com sucesso!', 5);
            } catch (e) {
              message.error(`Falha: ${e.message}`, 5);
            }
          }}
        >
          Salvar
        </Button>
      </span>
      {store.error ? (
        <div style={{ color: 'red', marginTop: 32 }}>
          Erro no carregamento das configurações.
        </div>
      ) : !store.initialized && store.loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 32 }}>
          <span>
            {configs.map((config, index) => {
              return (
                <Button
                  key={config.rssConfigId}
                  style={{ marginRight: 16 }}
                  type={
                    store.selectedConfig?.rssConfigId === config.rssConfigId
                      ? 'primary'
                      : 'default'
                  }
                  size="large"
                  disabled={store.loading}
                  onClick={() => store.selectConfig(index)}
                >
                  {config.description}
                </Button>
              );
            })}
          </span>
          <div style={{ marginTop: 32 }}>
            <h3>
              URL:
              <strong style={{ marginLeft: 8 }}>{selectedConfig.url}</strong>
              <Button
                style={{ marginLeft: 16 }}
                type="default"
                size="small"
                onClick={() => {
                  copy(selectedConfig.url);
                  message.success(
                    'URL copiada para a área de transferência.',
                    5
                  );
                }}
              >
                copiar
              </Button>
              <a
                href={selectedConfig.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{ marginLeft: 16 }}
                  type="default"
                  size="small"
                  onClick={() => {}}
                >
                  abrir
                </Button>
              </a>
            </h3>
          </div>
          <div style={{ marginTop: 24 }}>
            {selectedConfig.weekDays.map(({ weekDay, display }) => {
              return (
                <Button
                  key={`${weekDay}`}
                  style={{ width: '14.28%' }}
                  type={
                    selectedWeekDayNumber === weekDay ? 'primary' : 'default'
                  }
                  size="large"
                  disabled={store.loading}
                  onClick={() => store.selectWeekDay(weekDay)}
                >
                  {display}
                </Button>
              );
            })}
          </div>
          <div style={{ marginTop: 24 }}>
            {selectedWeekDay.slogans.map((slogan, index) => {
              return (
                <div
                  key={`${selectedWeekDayNumber}-${slogan.spTimeDisplay}`}
                  style={{}}
                >
                  <Input
                    addonBefore={slogan.spTimeDisplay}
                    value={slogan.slogan}
                    disabled={loading}
                    maxLength={100}
                    onChange={(e) => store.setSlogan(index, e.target.value)}
                    placeholder="Sem slogan"
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div style={{ height: 32 }} />
    </div>
  );
});
