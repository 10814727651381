import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoute from 'utils/privateRoute';
import { UserStore } from 'stores';
import { observer } from 'mobx-react';

import {
  Home,
  PushReport,
  UserReportNew,
  News,
  SentPushReport,
  NewsReport,
  Login,
  Error,
  RSSPage,
  UserAdmin,
  SubjectSponsor,
  TvConfig,
  TvBroadcast
} from './pages';

import './App.css';

const App = observer(() => {
  const { isLogged } = UserStore();
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isLogged ? <Home /> : <Login />}
          caseSensitive
        />
        <Route
          path="/pushes-por-oferecedor"
          element={<PrivateRoute component={PushReport} />}
          caseSensitive
        />
        <Route
          path="/relatorio-de-usuarios"
          element={<PrivateRoute component={UserReportNew} />}
          caseSensitive
        />
        <Route
          path="/relatorio-pushes-enviados"
          element={<PrivateRoute component={SentPushReport} />}
          caseSensitive
        />
        <Route
          path="/relatorio-de-noticias"
          element={<PrivateRoute component={NewsReport} />}
          caseSensitive
        />
        <Route
          path="/rss"
          element={<PrivateRoute component={RSSPage} />}
          caseSensitive
        />
        <Route
          path="/adicionar-noticia"
          element={<PrivateRoute component={News} mode="create" />}
          caseSensitive
        />
        <Route
          path="/adicionar-noticia/:id"
          element={<PrivateRoute component={News} mode="edit" />}
          caseSensitive
        />
        <Route
          path="/administrar-usuarios"
          element={<PrivateRoute component={UserAdmin} />}
          caseSensitive
        />
        <Route
          path="/patrocinio-editorias"
          element={<PrivateRoute component={SubjectSponsor} />}
          caseSensitive
        />
        <Route
          path="/configuracoes-tv"
          element={<PrivateRoute component={TvConfig} />}
          caseSensitive
        />
        <Route
          path="/transmissoes"
          element={<PrivateRoute component={TvBroadcast} />}
          caseSensitive
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
});

export default App;
