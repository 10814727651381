/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-await */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { useNavigate } from 'react-router-dom';
import { DatePicker, Button, Select, notification, Table } from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  FrownOutlined as FrownIcon
} from '@ant-design/icons';
import { StaticDataStore } from 'stores';
import { PushReportService } from 'services';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Loading from '../../components/Loading';

const dayOnOfTheMonth = (() => {
  const aux = new Date();
  aux.setDate(1);
  return moment(aux);
})();

const PushReport = observer(() => {
  const navigate = useNavigate();

  const { sponsors } = StaticDataStore();
  const [sponsorId, setSponsorId] = useState(sponsors[0].sponsorId);
  const [createdAtStart, setCreatedAtStart] = useState(dayOnOfTheMonth);
  const [createdAtEnd, setCreatedAtEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const onGenerateReportClick = async () => {
    setIsLoading(true);
    setData(null);
    try {
      if (!sponsorId) {
        notification.error({
          message: 'É preciso selecionar um oferecedor.',
          duration: 5
        });
        return;
      }
      if (!createdAtStart) {
        notification.error({
          message: 'É preciso informar a data inicial.',
          duration: 5
        });
        return;
      }
      setData(
        await PushReportService.getPushReportBySponsorAndDate({
          sponsorId,
          createdAtStart: createdAtStart.format('YYYY-MM-DD'),
          createdAtEnd: createdAtEnd?.format('YYYY-MM-DD')
        })
      );
    } catch (e) {
      notification.open({
        message: e.message,
        description: e.data?.message,
        duration: 0,
        icon: <FrownIcon style={{ color: '#ED0601' }} />
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        margin: 32,
        backgroundColor: 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div>
          <Select
            showSearch
            style={{ width: 180 }}
            value={sponsorId}
            onChange={(value) => setSponsorId(value)}
          >
            {sponsors.map(({ sponsorId: sponsorId_, name }) => (
              <Select.Option key={sponsorId_} value={sponsorId_}>
                {name}
              </Select.Option>
            ))}
          </Select>

          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data inicial"
            value={createdAtStart}
            onChange={(value) => {
              setCreatedAtStart(value);
            }}
          />
          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data final"
            value={createdAtEnd}
            onChange={(value) => setCreatedAtEnd(value)}
          />
        </div>

        <Button
          type="primary"
          size="large"
          style={{ width: 150 }}
          onClick={onGenerateReportClick}
          disabled={isLoading}
        >
          Gerar relatório
        </Button>
      </div>
      <div style={{ marginTop: 32 }}>
        {isLoading && <Loading />}

        {data && !isLoading && (
          <>
            <h4>
              {!data.length
                ? 'Nenhum regitro encontrado'
                : data.length === 1
                ? `${data.length} regitro encontrado`
                : `${data.length
                    .toString()
                    .replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      '.'
                    )} registros encontrados`}
            </h4>
            <Table
              bordered
              dataSource={data}
              style={{ marginTop: 16 }}
              columns={[
                {
                  title: 'createdAt',
                  dataIndex: 'createdAt'
                },
                {
                  title: 'title',
                  dataIndex: 'title'
                },
                {
                  title: 'subjectId',
                  dataIndex: 'subjectId'
                },
                {
                  title: 'total',
                  dataIndex: 'total'
                }
              ]}
            />
            <Button style={{ marginTop: 16, marginBottom: 48 }} size="large">
              <CSVLink filename="relatorio-oferecimento.csv" data={data}>
                Exportar para Excel
              </CSVLink>
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export default PushReport;
