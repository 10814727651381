const showSponsoredLang = (lang) => {
  if (lang === 'pt') {
    return 'Um oferecimento';
  }

  if (lang === 'en') {
    return 'Sponsored by';
  }

  if (lang === 'es') {
    return 'Una ofrenda';
  }

  if (lang === 'fr') {
    return 'Une offre';
  }

  if (lang === 'it') {
    return "Un'offerta";
  }

  return null;
};

export default showSponsoredLang;
