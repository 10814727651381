import packageInfo from '../../package.json';
import { Network, ServiceError } from './commons';

const adminVersion = packageInfo.adminVersion;

export const NewsService = {
  query: async ({ language, title } = {}) => {
    try {
      const { data } = await Network.get('admin/news', {
        params: {
          language,
          title
        }
      });
      return data;
    } catch (e) {
      throw new ServiceError('news.index', e);
    }
  },
  show: async (newsId) => {
    try {
      const { data } = await Network.get(`admin/news/${newsId}`);
      return data;
    } catch (e) {
      throw new ServiceError('news.show', e);
    }
  },
  create: async (news) => {
    try {
      const { data } = await Network.post('admin/news', {
        ...news,
        adminVersion
      });
      return data;
    } catch (e) {
      throw new ServiceError('news.create', e);
    }
  },
  replace: async (newsId, news) => {
    try {
      const { data } = await Network.put(`admin/news/${newsId}`, {
        ...news,
        adminVersion
      });
      return data;
    } catch (e) {
      throw new ServiceError('news.update', e);
    }
  },
  remove: async (newsId) => {
    try {
      await Network.delete(`admin/news/${newsId}`);
    } catch (e) {
      throw new ServiceError('news.remove', e);
    }
  },
  getLatestImages: async () => {
    try {
      const { data } = await Network.get('admin/news/latest_images');
      return data;
    } catch (e) {
      throw new ServiceError('news.getLatestImages', e);
    }
  },
  getPushCount: async (news) => {
    try {
      const { data } = await Network.get('admin/news/push_count', {
        params: news
      });
      return data?.count;
    } catch (e) {
      console.log(e);
      throw new ServiceError('news.getPushCount', e);
    }
  }
};
