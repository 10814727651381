import { Network, ServiceError } from './commons';

export const UserAdminService = {
  list: async () => {
    try {
      const { data } = await Network.get('admin/user');
      return data?.users;
    } catch (e) {
      throw new ServiceError('user.list', e);
    }
  },
  create: async ({ email, pwd, name, master, reports }) => {
    try {
      const { data } = await Network.post('admin/user', {
        email,
        pwd,
        name,
        master,
        reports
      });
      return data;
    } catch (e) {
      throw new ServiceError('user.create', e);
    }
  },
  activate: async ({ userId }) => {
    try {
      await Network.post('admin/user/activate', { userId });
    } catch (e) {
      throw new ServiceError('user.activate', e);
    }
  },
  deactivate: async ({ userId }) => {
    try {
      await Network.delete('admin/user', { data: { userId } });
    } catch (e) {
      throw new ServiceError('user.deactivate', e);
    }
  }
};
