import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { Button, Input, message, Typography } from 'antd';
import { ArrowLeftOutlined as ArrowLeftIcon } from '@ant-design/icons';
import { TvConfigStore } from 'stores';

import Loading from '../../components/Loading';
import { ITvConfig } from 'models';

const TvConfigPage = observer(() => {
  const [updateSelection, setUpdateSelection] = useState<ITvConfig[]>([]);
  const [isChanged, setIsChanged] = useState(false);

  const store = TvConfigStore();
  const navigate = useNavigate();
  const { loading, configs } = store;

  const handleChange = (config: ITvConfig, adId: string) => {
    const updatedConfig = { ...config, adId: adId || null };
    const itemIndex = updateSelection.findIndex(
      (item) => item.utcStartTime === config.utcStartTime
    );

    setUpdateSelection((prevState) =>
      prevState.filter((_, idx) => idx !== itemIndex).concat(updatedConfig)
    );
  };

  const handleSubmit = async () => {
    try {
      await store.update(updateSelection);
      message.success('Configurações salvas com sucesso!', 5);
    } catch (e: any) {
      message.error(`Falha: ${e.message}`, 5);
    }
  };

  useEffect(() => {
    setIsChanged(!!updateSelection.length);
  }, [updateSelection.length]);

  useEffect(() => {
    store.reset();
  }, [store]);

  return (
    <div style={{ margin: 32 }}>
      <span>
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: 32
          }}
          type="primary"
          size="large"
          loading={store.loading}
          disabled={store.loading || !isChanged}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </span>
      {store.error ? (
        <div style={{ color: 'red', marginTop: 32 }}>
          Erro no carregamento das configurações.
        </div>
      ) : !store.initialized && store.loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 32 }}>
          <Typography.Title style={{ fontSize: 20 }}>
            Configurações de anúncios na TV
          </Typography.Title>

          <div style={{ marginTop: 24 }}>
            {configs.map((config) => {
              return (
                <div key={config.utcStartTime} style={{}}>
                  <Input
                    addonBefore={config.spStartTime}
                    defaultValue={config.adId || undefined}
                    disabled={loading}
                    maxLength={100}
                    onChange={(e) => handleChange(config, e.target.value)}
                    placeholder="Id de anúncio"
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div style={{ height: 32 }} />
    </div>
  );
});

export default TvConfigPage;
