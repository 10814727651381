/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  message,
  Table,
  Space,
  Popconfirm,
  Typography,
  Empty,
  DatePicker,
  Modal
} from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  PlusOutlined as PlusIcon,
  UnorderedListOutlined as UserListIcon
} from '@ant-design/icons';
import { UserStore, TvBroadcastStore, StaticDataStore } from 'stores';

import Loading from 'components/Loading';
import { ICountry, ILanguage, ITvBroadcast } from 'models';

const validateMessages = {
  required: '${label} é um campo obrigatório!',
  types: {
    email: '${label} não é um email válido!'
  }
};

const tabKeys = {
  listBroadcasts: 'list-broadcasts',
  newBroadcast: 'new-broadcast'
};

const { Option } = Select;

const toDate = (dateStr: string | undefined) => {
  if (!dateStr) {
    return undefined;
  }

  return new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'long',
    timeStyle: 'short'
  }).format(new Date(dateStr));
};

const TvBroadcastPage = observer(() => {
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<ITvBroadcast[] | undefined>();

  const [activeTab, setActiveTab] = useState(tabKeys.listBroadcasts);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<ITvBroadcast>();

  const userStore = UserStore();
  const staticDataStore = StaticDataStore();
  const { initialized: sdInitialized, error: sdError } = staticDataStore;

  const store = TvBroadcastStore();
  const { loading, initialized, error, broadcasts, remove, create } = store;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onTabChange = (key: string) => setActiveTab(key);
  const onResetForm = useCallback(() => form.resetFields(), [form]);

  const openItem = (item: ITvBroadcast) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const onSubmit = async (
    values: Pick<
      ITvBroadcast,
      | 'priority'
      | 'name'
      | 'url'
      | 'active'
      | 'featured'
      | 'videoType'
      | 'startAt'
      | 'endAt'
      | 'adId'
      | 'image'
      | 'country'
      | 'language'
    >
  ) => {
    try {
      setSubmitting(true);
      await create({
        priority: values.priority,
        name: values.name,
        url: values.url,
        active: values.active || false,
        featured: values.featured || false,
        videoType: values.videoType,
        startAt: values.startAt,
        endAt: values.endAt,
        adId: values.adId,
        image: values.image,
        country: values.country,
        language: values.language
      });
      message.success('Nova transmissão criada com sucesso!', 5);
      setActiveTab(tabKeys.listBroadcasts);
      onResetForm();
    } catch (e: any) {
      const errorMessage = e?.data?.message || e.message;
      message.error(`Falha ao criar nova transmissão: ${errorMessage}`, 5);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRemoveTvBroadcast = useCallback(
    async (tvBroadcast: ITvBroadcast) => {
      try {
        await remove({ id: tvBroadcast.id });
        message.success(
          `Transmissão ${tvBroadcast.name} removida com sucesso!`,
          5
        );
        onResetForm();
      } catch (e: any) {
        message.error(
          `Falha ao remover transmissão ${tvBroadcast.name}: ${e.message}`,
          5
        );
      }
    },
    [onResetForm, remove]
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Prioridade',
        dataIndex: 'priority',
        key: 'priority',
        sorter: (a: ITvBroadcast, b: ITvBroadcast) => a.priority - b.priority
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'id',
        render: (text: string, record: ITvBroadcast) => (
          <Typography.Link onClick={() => openItem(record)}>
            {text}
          </Typography.Link>
        ),
        sorter: (a: ITvBroadcast, b: ITvBroadcast) =>
          a?.name?.localeCompare(b?.name, 'pt', {
            sensitivity: 'base'
          })
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        sorter: (a: ITvBroadcast, b: ITvBroadcast) =>
          a?.url?.localeCompare(b?.url, 'pt', { sensitivity: 'base' })
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        key: 'active',
        width: '10%'
      },
      {
        title: 'Destacado',
        dataIndex: 'featured',
        key: 'featured',
        width: '10%'
      },
      {
        title: 'Ações',
        key: 'action',
        render: (_: any, record: ITvBroadcast) => {
          return (
            <Space size="middle">
              <Popconfirm
                title={`Tem certeza que deseja remover a transmissão ${record.name}?`}
                onConfirm={() => handleRemoveTvBroadcast(record)}
                cancelText="Cancelar"
                okText="Sim"
              >
                <Typography.Link>Remover</Typography.Link>
              </Popconfirm>
            </Space>
          );
        },
        width: '15%'
      }
    ];
  }, [handleRemoveTvBroadcast]);

  const content = {
    [tabKeys.newBroadcast]: (
      <Form
        form={form}
        labelCol={{ span: 3 }}
        labelAlign="left"
        wrapperCol={{ span: 8 }}
        layout="horizontal"
        validateMessages={validateMessages}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="priority"
            label="Prioridade"
            rules={[{ required: true }]}
          >
            <InputNumber
              name="priority"
              min={0}
              max={256}
              defaultValue={1}
              disabled={loading}
              placeholder="Prioridade"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
            <Input
              name="name"
              maxLength={100}
              disabled={loading}
              placeholder="Nome"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="url" label="URL" rules={[{ required: true }]}>
            <Input
              name="url"
              maxLength={255}
              disabled={loading}
              placeholder="URL"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="active"
            label="Ativo"
            wrapperCol={{ span: 10 }}
            valuePropName="checked"
          >
            <Switch defaultChecked={true} />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="featured"
            label="Destacado"
            wrapperCol={{ span: 10 }}
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="videoType" label="Tipo do Vídeo">
            <Input
              name="videoType"
              maxLength={100}
              disabled={loading}
              placeholder="Tipo do Vídeo"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="startAt" label="Data de Início">
            <DatePicker name="startAt" showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="endAt" label="Data de Término">
            <DatePicker name="endAt" showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="adId" label="Ad ID">
            <Input
              name="adId"
              maxLength={255}
              disabled={loading}
              placeholder="Ad ID"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="image" label="Imagem">
            <Input
              name="image"
              maxLength={255}
              disabled={loading}
              placeholder="Imagem"
            />
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="country" label="País" rules={[{ required: true }]}>
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.sponsorCountries?.map((country: ICountry) => (
                <Option key={country.country} value={country.country}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="language"
            label="Idioma"
            rules={[{ required: true }]}
          >
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.languages?.map((language: ILanguage) => (
                <Option key={language.language} value={language.language}>
                  {language.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginTop: 45 }}>
          <Button
            type="primary"
            size="large"
            disabled={submitting}
            loading={submitting}
            htmlType="submit"
          >
            Criar Transmissão
          </Button>
        </div>
      </Form>
    ),
    [tabKeys.listBroadcasts]: (
      <Form
        form={form}
        layout="horizontal"
        autoComplete="off"
        validateMessages={validateMessages}
        disabled={submitting}
      >
        <Table
          dataSource={data}
          columns={columns}
          showSorterTooltip={false}
          rowClassName="editable-row"
          rowKey="id"
          loading={loading || submitting}
          pagination={{ defaultPageSize: 50 }}
          bordered
          locale={{
            emptyText: () => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Nenhuma informação encontrada</span>}
              />
            )
          }}
        />
      </Form>
    )
  };

  useEffect(() => {
    if (!staticDataStore.initialized) staticDataStore.reset();
  }, [staticDataStore]);

  useEffect(() => {
    if (!store.initialized) store.reset();
  }, [store]);

  useEffect(() => {
    if (!sdInitialized) {
      return;
    }

    const fetchData = (): ITvBroadcast[] =>
      broadcasts?.map((broadcast) => {
        const language = staticDataStore.getLanguageName(broadcast.language);
        const country = staticDataStore.getCountryName(broadcast.country);

        return {
          ...broadcast,
          country,
          language
        };
      });

    const data = fetchData().map((item) => {
      return {
        ...item,
        key: `item_${item.id}`
      };
    });

    setData(data);
  }, [sdInitialized, staticDataStore, broadcasts]);

  if (!userStore.isAdmin) {
    return (
      <div style={{ margin: 32 }}>
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div style={{ color: 'red', marginTop: 32 }}>
          Essa página está disponível apenas para Administradores.
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: 32 }}>
      <Button
        icon={<ArrowLeftIcon />}
        size="large"
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>

      {error || sdError ? (
        <div style={{ color: 'red', marginTop: 32 }}>
          Erro no carregamento das informações.
        </div>
      ) : (!initialized && loading) || !data ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 32 }}>
          <Card
            style={{ width: '100%' }}
            tabList={[
              {
                key: tabKeys.listBroadcasts,
                tab: (
                  <span>
                    <UserListIcon />
                    Transmissões
                  </span>
                )
              },
              {
                key: tabKeys.newBroadcast,
                tab: (
                  <span>
                    <PlusIcon />
                    Nova transmissão
                  </span>
                )
              }
            ]}
            activeTabKey={activeTab}
            onTabChange={(key) => onTabChange(key)}
          >
            {content[activeTab]}
          </Card>
        </div>
      )}
      <div style={{ height: 32 }} />

      <Modal
        title={currentItem?.name}
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setModalOpen(false)}>
            Ok
          </Button>
        ]}
      >
        <p>
          <strong>Prioridade:</strong> {currentItem?.priority}
        </p>
        <p>
          <strong>URL:</strong> {currentItem?.url}
        </p>
        <p>
          <strong>Ativo:</strong> {currentItem?.active ? 'Sim' : 'Não'}
        </p>
        <p>
          <strong>Destaque:</strong> {currentItem?.featured ? 'Sim' : 'Não'}
        </p>
        <p>
          <strong>Tipo do Vídeo:</strong> {currentItem?.videoType}
        </p>
        <p>
          <strong>Data de Início:</strong> {toDate(currentItem?.startAt)}
        </p>
        <p>
          <strong>Data de Término:</strong> {toDate(currentItem?.endAt)}
        </p>
        <p>
          <strong>Add ID:</strong> {currentItem?.adId}
        </p>
        <p>
          <strong>Imagem:</strong> {currentItem?.image}
        </p>
        <p>
          <strong>País:</strong> {currentItem?.country}
        </p>
        <p>
          <strong>Idioma:</strong> {currentItem?.language}
        </p>
      </Modal>
    </div>
  );
});

export default TvBroadcastPage;
