import { memo } from 'react';
import { Form, Select } from 'antd';
import { ISubjectSponsor } from 'models';

interface SubjectSponsorCellOptionsProps {
  value: string;
  name: string;
  data: any[];
}

interface SubjectSponsorCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: ISubjectSponsor;
  index: number;
  children: React.ReactNode;
  options: SubjectSponsorCellOptionsProps;
}

const { Option } = Select;

export const SubjectSponsorCell: React.FC<SubjectSponsorCellProps> = memo(
  function SubjectSponsorCell({
    editing,
    dataIndex,
    title,
    record,
    index,
    options,
    children,
    ...restProps
  }) {
    return (
      <td {...restProps} style={{ height: '64px' }}>
        {editing ? (
          <Form.Item
            name={options.value}
            rules={[{ required: true }]}
            style={{ marginBottom: 0, marginLeft: '-12px' }}
          >
            <Select maxLength={100}>
              {options?.data.map((option: any, idx: number) => (
                <Option
                  key={option[options.value] || idx}
                  value={option[options.value]}
                >
                  {option[options.name]}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }
);
