import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const MenuItem = ({ icon, description, active, onClick, itemDestac, path }) => (
  <Link
    to={path || ''}
    className={`menu--item ${itemDestac && 'menu--itemDestac'} ${active}`}
    onClick={onClick}
  >
    {icon}
    <span>{description}</span>
  </Link>
);

export default MenuItem;
