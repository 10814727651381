/* eslint-disable react/no-array-index-key */
import React from 'react';

import { RiExternalLinkLine as ExternalLinkIcon } from 'react-icons/ri';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { NewsEditorStore } from 'stores';
import { observer } from 'mobx-react';
import Loading from 'components/Loading';

import showReadLang from '../../utils/showReadLang';

const PreviewBody = observer(() => {
  const news = NewsEditorStore();
  const {
    loading,
    createdAt,
    language,
    title,
    imageUrl,
    imageDescription,
    sourceUrl,
    content
  } = news;

  const formattedCreatedAt = createdAt ? new Date(createdAt) : new Date();
  const formattedDate = format(formattedCreatedAt, 'dd/MMM H:mm', {
    locale: ptBR
  });
  const fragmentedContent = (content || '').split(/\n/);

  return (
    <section className="preview--content">
      <header className="preview--header">
        <span>{formattedDate}</span>
        <h2>{title}</h2>
      </header>

      {loading ? (
        <Loading />
      ) : (
        <>
          {imageUrl && (
            <figure className="preview--thumb">
              <img src={imageUrl} alt={title} />
              <figcaption>{imageDescription}</figcaption>
            </figure>
          )}
          <div className="preview--description">
            {Object.keys(fragmentedContent).map((item, index) => {
              const token = fragmentedContent[item];
              return token ? (
                <p key={index} style={{ marginBottom: 4 }}>
                  {token}
                </p>
              ) : (
                <div key={index} style={{ height: 16 }} />
              );
            })}
          </div>

          {!!sourceUrl && (
            <div className="preview--containerLink">
              <a
                className="preview--link"
                href={sourceUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <ExternalLinkIcon
                  style={{
                    fontSize: '18px',
                    color: '#000',
                    marginRight: '4px'
                  }}
                />
                {showReadLang(language)}
              </a>
            </div>
          )}
        </>
      )}
    </section>
  );
});

export default PreviewBody;
