/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Modal, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  FrownOutlined as FrownIcon
} from '@ant-design/icons';

import PreviewApp from 'components/PreviewApp';
import Preview from 'components/Preview';
import { NewsEditorStore } from 'stores';
import { observer } from 'mobx-react';

import {
  AddChannelModal,
  AddCountryModal,
  AddLangModal,
  AddPhotoModal,
  AddSponsorModal
} from 'components/Modals';

import './styles.css';

function isValidHttpUrl(urlText) {
  let url;

  try {
    url = new URL(urlText);
  } catch (e) {
    return false;
  }

  return (
    (url.protocol === 'http:' || url.protocol === 'https:') &&
    !urlText.includes(' ')
  );
}

const News = observer(() => {
  const news = NewsEditorStore();
  const inserting = !news.newsId;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [visibleModalLang, setVisibleModalLang] = useState(false);
  const [visibleModalCountry, setVisibleModalCountry] = useState(false);
  const [visibleModalChannel, setVisibleModalChannel] = useState(false);
  const [visibleModalSponsor, setVisibleModalSponsor] = useState(false);
  const [visibleModalSponsorRss, setVisibleModalSponsorRss] = useState(false);
  const [visibleModalPhoto, setVisibleModalPhoto] = useState(false);
  const [loadingAudience, setLoadingAudience] = useState(false);
  const [pushCount, setPushCount] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      title: news.title,
      content: news.content,
      sourceUrl: news.sourceUrl,
      sendPushOnlyToStr: news.sendPushOnlyToStr
    });
  }, [news, form]);

  const basicNotificationWarning = (title, content) => {
    Modal.warning({
      title,
      content: <h3>{content}</h3>,
      duration: 2
    });
  };

  const notificationWarning = (itemEmpty) => {
    basicNotificationWarning('É necessário preencher o campo', itemEmpty);
  };

  const notificationError = (msgError, codeError) => {
    notification.open({
      message: 'Ocorreu um erro!',
      description: (
        <>
          <strong>Código:</strong> {codeError}
          <br />
          <strong>Mensagem:</strong> {msgError}
        </>
      ),
      style: { width: 600 },
      duration: 0,
      icon: <FrownIcon style={{ color: '#ED0601' }} />
    });
  };

  // Visibilidade dos modais
  // -----------------------
  const handleVisibleModal = ({ key }) => {
    switch (key) {
      case 'lang':
        setVisibleModalLang(!visibleModalLang);
        break;

      case 'country':
        setVisibleModalCountry(!visibleModalCountry);
        break;

      case 'channel':
        setVisibleModalChannel(!visibleModalChannel);
        break;

      case 'sponsor':
        setVisibleModalSponsor(!visibleModalSponsor);
        break;

      case 'sponsorRss':
        setVisibleModalSponsorRss(!visibleModalSponsorRss);
        break;

      case 'photo':
        setVisibleModalPhoto(!visibleModalPhoto);
        break;

      default:
        break;
    }
  };

  const onCalculateAudience = async () => {
    if (!news.language) {
      notificationWarning('Idioma');
      return;
    }

    if (!news.subjectId) {
      notificationWarning('Assunto');
      return;
    }

    setLoadingAudience(true);

    try {
      setPushCount(await NewsEditorStore().getPushCount());
    } catch (e) {
      notificationError(e.message, e.status);
    } finally {
      setLoadingAudience(false);
    }
  };

  const onFinishPage = async () => {
    if (!news.language) {
      notificationWarning('Idioma');
      return;
    }

    if (!news.subjectId) {
      notificationWarning('Assunto');
      return;
    }

    if (!news.imageUrl) {
      notificationWarning('Foto');
      return;
    }

    setShowConfirmModal(true);
  };

  return (
    <>
      <div className="container--addNews">
        <section className="container--form">
          <div className="buttons--options">
            <Button
              size="large"
              disabled={loading || loadingAudience}
              onClick={() => handleVisibleModal({ key: 'lang' })}
            >
              {news.languageName || 'Idioma'}
            </Button>
            <Button
              size="large"
              disabled={loading || loadingAudience}
              onClick={() => handleVisibleModal({ key: 'country' })}
            >
              {news.countries?.length
                ? news.countries.join()
                : 'Todos os países'}
            </Button>
            <Button
              size="large"
              disabled={loading || loadingAudience}
              onClick={() => handleVisibleModal({ key: 'channel' })}
            >
              {news.subjectNamePt || 'Assunto'}
            </Button>
            <Button
              size="large"
              disabled={loading || loadingAudience}
              onClick={() => handleVisibleModal({ key: 'photo' })}
            >
              Foto
            </Button>
            <Button
              size="large"
              disabled={loading || loadingAudience}
              onClick={() => handleVisibleModal({ key: 'sponsor' })}
            >
              {news.sponsorData?.name || 'Sem oferecedor'}
            </Button>
          </div>

          <Form form={form} onFinish={onFinishPage}>
            <div style={{ marginBottom: '16px' }}>
              <Form.Item
                name="title"
                rules={[
                  { required: true, message: 'Título é um campo obrigatório' }
                ]}
              >
                <Input
                  name="title"
                  value={news.title}
                  maxLength={100}
                  disabled={loading || loadingAudience}
                  onChange={(e) => news.setTitle(e.target.value)}
                  placeholder="Notícia"
                  addonAfter={
                    <span
                      style={{
                        color: news.title?.length > 64 ? 'red' : '#aaa',
                        backgroundColor: 'white'
                      }}
                    >
                      {news.title?.length || 0} caracteres
                    </span>
                  }
                />
              </Form.Item>

              <div className="wrapperContent">
                <Form.Item
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Conteúdo é um campo obrigatório'
                    }
                  ]}
                >
                  <Input.TextArea
                    name="content"
                    value={news.content}
                    disabled={loading || loadingAudience}
                    maxLength={2000}
                    onChange={(e) => news.setContent(e.target.value)}
                    placeholder="Conteúdo da notícia"
                  />
                </Form.Item>
                <span className="contentCounter">
                  {news.content?.length || 0} caracteres
                </span>
              </div>

              <Form.Item
                name="sourceUrl"
                rules={[
                  {
                    validator: (_, value) =>
                      !value || (value && isValidHttpUrl(value))
                        ? Promise.resolve()
                        : Promise.reject(new Error('Link inválido'))
                  }
                ]}
              >
                <Input
                  name="sourceUrl"
                  value={news.sourceUrl}
                  disabled={loading || loadingAudience}
                  maxLength={200}
                  onChange={(e) => news.setSourceUrl(e.target.value)}
                  placeholder="Link para a notícia original (começando com http:// ou https://)"
                />
              </Form.Item>

              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Form.Item name="sendPush">
                  <Checkbox
                    checked={news.sendPush}
                    disabled={loadingAudience || loading}
                    onChange={(e) => news.setSendPush(e.target.checked)}
                  >
                    Enviar Notificações
                  </Checkbox>
                </Form.Item>

                <Form.Item name="lettering">
                  <Checkbox
                    checked={news.lettering}
                    disabled={loadingAudience || loading}
                    onChange={(e) => news.setLettering(e.target.checked)}
                  >
                    Exibir no Lettering
                  </Checkbox>
                </Form.Item>

                <Button
                  disabled={!news.lettering || loading || loadingAudience}
                  style={{ width: 200 }}
                  onClick={() => handleVisibleModal({ key: 'sponsorRss' })}
                >
                  {news.sponsorRssData?.name
                    ? `Apoio: ${news.sponsorRssData?.name}`
                    : 'Sem apoio'}
                </Button>
              </div>

              <Form.Item name="sendPushOnlyToStr">
                <Input
                  disabled={
                    !inserting || loading || loadingAudience || !news.sendPush
                  }
                  name="sendPushOnlyToStr"
                  value={news.sendPushOnlyToStr}
                  onChange={(e) => news.setSendPushOnlyTo(e.target.value)}
                  placeholder="Enviar push apenas para: (informe os e-mails separados por vírgula)"
                />
              </Form.Item>
            </div>

            <div className="buttons--submit">
              <Button
                icon={<ArrowLeftIcon />}
                size="large"
                onClick={() => {
                  if (!inserting) {
                    news.cancelEditMode();
                  }
                  navigate(-1);
                }}
              >
                Lista de notícias
              </Button>

              <div className="submit--actions">
                <Button
                  size="large"
                  loading={loadingAudience}
                  disabled={loadingAudience || loading}
                  onClick={onCalculateAudience}
                >
                  {pushCount === null
                    ? 'Calcular pushes'
                    : `Pushes: ${pushCount}`}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  disabled={loading}
                  loading={loading}
                  htmlType="submit"
                >
                  Revisar
                </Button>
              </div>
            </div>
          </Form>
        </section>
        <section className="container--addPreview">
          <>
            <PreviewApp news={news} />
            <Preview news={news} />
          </>
        </section>
      </div>

      <AddLangModal
        visible={visibleModalLang}
        onCancel={() => handleVisibleModal({ key: 'lang' })}
        onOk={() => handleVisibleModal({ key: 'lang' })}
      />

      <AddCountryModal
        visible={visibleModalCountry}
        onCancel={() => handleVisibleModal({ key: 'country' })}
        onOk={() => handleVisibleModal({ key: 'country' })}
      />

      <AddChannelModal
        visible={visibleModalChannel}
        onCancel={() => handleVisibleModal({ key: 'channel' })}
        onOk={() => handleVisibleModal({ key: 'channel' })}
      />

      <AddPhotoModal
        visible={visibleModalPhoto}
        onCancel={() => handleVisibleModal({ key: 'photo' })}
      />

      <AddSponsorModal
        title="Selecione um oferecedor"
        currentSponsor={news.sponsorId}
        visible={visibleModalSponsor}
        onCancel={() => handleVisibleModal({ key: 'sponsor' })}
        onOk={(sponsorId) => {
          NewsEditorStore().setSponsorId(sponsorId);
          handleVisibleModal({ key: 'sponsor' });
        }}
      />

      <AddSponsorModal
        title="Selecione o apoio"
        currentSponsor={news.sponsorIdRss}
        visible={visibleModalSponsorRss}
        onCancel={() => handleVisibleModal({ key: 'sponsorRss' })}
        onOk={(sponsorId) => {
          NewsEditorStore().setSponsorIdRss(sponsorId);
          handleVisibleModal({ key: 'sponsorRss' });
        }}
      />

      {showConfirmModal && (
        <Modal
          destroyOnClose
          open
          title="Confirme os dados da notícia"
          okText="Confirmar"
          okButtonProps={{ size: 'large' }}
          cancelButtonProps={{ size: 'large' }}
          cancelText="Cancelar"
          onOk={async () => {
            setShowConfirmModal(false);
            setLoading(true);

            try {
              await NewsEditorStore().save();

              navigate(-1);
            } catch (e) {
              notificationError(e.message, e.status);
            } finally {
              setLoading(false);
            }
          }}
          onCancel={() => setShowConfirmModal(false)}
          width={800}
          forceRender
        >
          <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
            {news.title}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.languageName}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.countries?.length ? news.countries : 'Todos os países'}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.subjectNamePt}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.sponsorData?.name ? news.sponsorData?.name : 'Sem oferecedor'}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.sendPush ? 'Enviar notificações' : 'Não enviar notificações'}
          </p>

          {news.sendPush && news.sendPushOnlyToStr && (
            <p style={{ fontSize: 20, textAlign: 'center' }}>
              {news.sendPushOnlyToStr}
            </p>
          )}

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.lettering ? 'Exibir no lettering' : 'Não exibir no lettering'}
          </p>

          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {news.sponsorRssData?.name
              ? `apoio ${news.sponsorRssData?.name}`
              : 'Sem apoio'}
          </p>
        </Modal>
      )}
    </>
  );
});

export default News;
