import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import Loading from 'components/Loading';
import NewsItem from 'components/NewsItem';

import { NewsListStore, NewsEditorStore } from 'stores';

import './styles.css';

const NewsList = observer(() => {
  const { initialized, reset, waiting, list } = NewsListStore();

  useEffect(() => {
    if (!initialized) {
      reset();
    }
  }, [initialized, reset]);

  return waiting ? (
    <Loading />
  ) : (
    list.map((item) => (
      <article
        className={`news ${
          NewsEditorStore().newsId === item.newsId && 'news--active'
        } ${item.deleted && 'news--deleted'}`}
        onClick={() => {
          NewsEditorStore().setSelectedNews(item);
        }}
        key={item.newsId}
        aria-hidden="true"
      >
        <NewsItem news={item} />
      </article>
    ))
  );
});

export default NewsList;
