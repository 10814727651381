import React from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import './styles.css';

const NewsItem = ({ news }) => {
  const { title, createdAt, language, subjectName, thumbUrl, countries } = news;

  const createdAtStr = new Date(createdAt);
  const formattedDate = format(createdAtStr, 'd/MMM H:mm', {
    // D/MMM H:mm
    locale: ptBR
  });

  return (
    <>
      <div
        className="news--thumb"
        style={{ backgroundImage: `url(${thumbUrl})` }}
      >
        <div className="news--language">
          {countries?.length ? (
            <span>{`${language}-${countries.join()}`}</span>
          ) : (
            <span>{language}</span>
          )}
        </div>
      </div>

      <div className="news--content">
        <span className="news--meta">{`${formattedDate}・${subjectName}`}</span>
        <p className="news--title">{title}</p>
      </div>
    </>
  );
};

export default NewsItem;
