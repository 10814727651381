/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  Button,
  Form,
  Select,
  message,
  Table,
  Space,
  Popconfirm,
  Typography,
  Empty
} from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  CrownOutlined as SponsorIcon,
  UnorderedListOutlined as UserListIcon
} from '@ant-design/icons';
import { UserStore, SubjectSponsorStore, StaticDataStore } from 'stores';

import Loading from 'components/Loading';
import { SubjectSponsorCell } from 'components/SubjectSponsor';
import type {
  ICountry,
  ILanguage,
  ISponsor,
  ISubject,
  ISubjectSponsor
} from 'models';

const validateMessages = {
  required: '${label} é um campo obrigatório!',
  types: {
    email: '${label} não é um email válido!'
  }
};

const tabKeys = {
  listSubjectSponsors: 'list-subject-sponsors',
  newSubjectSponsor: 'new-subject-sponsor'
};

const { Option } = Select;

const SubjectSponsor = observer(() => {
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState<ISubjectSponsor[] | undefined>();

  const [activeTab, setActiveTab] = useState(tabKeys.listSubjectSponsors);
  const [editingRow, setEditingRow] = useState<number | undefined | null>(null);

  const userStore = UserStore();
  const staticDataStore = StaticDataStore();
  const { initialized: sdInitialized, error: sdError } = staticDataStore;

  const store = SubjectSponsorStore();
  const { loading, initialized, error, subjectSponsors, remove, create, edit } =
    store;

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onTabChange = (key: string) => setActiveTab(key);
  const onResetForm = useCallback(() => form.resetFields(), [form]);
  const cancelEdition = useCallback(() => {
    onResetForm();
    setEditingRow(null);
  }, [onResetForm]);

  const onSubmit = async (
    values: Pick<
      ISubjectSponsor,
      'country' | 'language' | 'sponsorId' | 'subjectId'
    >
  ) => {
    try {
      setSubmitting(true);
      await create({
        country: values.country,
        language: values.language,
        sponsorId: values.sponsorId,
        subjectId: values.subjectId
      });
      message.success('Novo patrocínio de editoria criado com sucesso!', 5);
      setActiveTab(tabKeys.listSubjectSponsors);
      onResetForm();
    } catch (e: any) {
      const errorMessage = e?.data?.message || e.message;
      message.error(
        `Falha ao criar novo patrocínio de editoria: ${errorMessage}`,
        5
      );
    } finally {
      setSubmitting(false);
    }
  };

  const isEditing = useCallback(
    (record: ISubjectSponsor) => record.subjectSponsorId === editingRow,
    [editingRow]
  );

  const editRow = useCallback(
    (record: Partial<ISubjectSponsor> & { key: React.Key }) => {
      form.setFieldsValue({ ...record });
      setEditingRow(record.subjectSponsorId);
    },
    [form]
  );

  const handleRemoveSubjectSponsor = useCallback(
    async (subjectSponsor: ISubjectSponsor) => {
      try {
        await remove({ subjectSponsorId: subjectSponsor.subjectSponsorId });
        message.success(
          `Patrocínio ${subjectSponsor.sponsorName} da editoria ${subjectSponsor.subjectName} removido com sucesso!`,
          5
        );
        onResetForm();
      } catch (e: any) {
        message.error(
          `Falha ao remover patrocínio ${subjectSponsor.sponsorName} da editoria: ${subjectSponsor.subjectName}: ${e.message}`,
          5
        );
      }
    },
    [onResetForm, remove]
  );

  const onSubmitEdition = useCallback(
    async (
      values: Pick<
        ISubjectSponsor,
        'country' | 'language' | 'sponsorId' | 'subjectId' | 'subjectSponsorId'
      >
    ) => {
      try {
        setSubmitting(true);
        await edit({
          subjectSponsorId: values.subjectSponsorId,
          country: values.country,
          language: values.language,
          sponsorId: values.sponsorId,
          subjectId: values.subjectId
        });
        message.success('Patrocínio de editoria editado com sucesso!', 5);
        onResetForm();
      } catch (e: any) {
        const errorMessage = e?.data?.message || e.message;
        message.error(
          `Falha ao editar patrocínio de editoria: ${errorMessage}`,
          5
        );
      } finally {
        setSubmitting(false);
      }
    },
    [edit, onResetForm]
  );

  const saveEdition = useCallback(
    async (subjectSponsor: ISubjectSponsor) => {
      if (!form.isFieldsTouched()) {
        return cancelEdition();
      }

      const row = (await form.validateFields()) as ISubjectSponsor;
      const isCountryEdited = form.isFieldTouched('country');
      const isLanguageEdited = form.isFieldTouched('language');

      const { subjectSponsorId } = subjectSponsor;

      const editedSubjectSponsor: ISubjectSponsor = {
        ...row,
        subjectSponsorId,
        country: isCountryEdited
          ? row.country
          : staticDataStore?.sponsorCountries?.find(
              (item: ICountry) => item.name === row.country
            ).country,
        language: isLanguageEdited
          ? row.language
          : staticDataStore?.languages?.find(
              (item: ILanguage) => item.name === row.language
            ).language
      };

      await onSubmitEdition(editedSubjectSponsor);
      setEditingRow(null);
    },
    [
      cancelEdition,
      form,
      onSubmitEdition,
      staticDataStore?.languages,
      staticDataStore?.sponsorCountries
    ]
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'Editoria',
        dataIndex: 'subjectName',
        key: 'subjectId',
        render: (text: React.ReactElement) => (
          <Typography.Link>{text}</Typography.Link>
        ),

        sorter: (a: ISubjectSponsor, b: ISubjectSponsor) =>
          a?.subjectName?.localeCompare(b?.subjectName, 'pt', {
            sensitivity: 'base'
          }),
        width: '20%',
        editable: true,
        options: {
          data: staticDataStore?.subjects,
          value: 'subjectId',
          name: 'pt'
        }
      },
      {
        title: 'País',
        dataIndex: 'country',
        key: 'country',
        sorter: (a: ISubjectSponsor, b: ISubjectSponsor) =>
          a?.country?.localeCompare(b?.country, 'pt', { sensitivity: 'base' }),
        width: '15%',
        editable: true,
        options: {
          data: staticDataStore?.sponsorCountries,
          value: 'country',
          name: 'name'
        }
      },
      {
        title: 'Idioma',
        dataIndex: 'language',
        key: 'language',
        sorter: (a: ISubjectSponsor, b: ISubjectSponsor) =>
          a?.language?.localeCompare(b?.language, 'pt', {
            sensitivity: 'base'
          }),
        width: '15%',
        editable: true,
        options: {
          data: staticDataStore?.languages,
          value: 'language',
          name: 'name'
        }
      },
      {
        title: 'Patrocinador',
        dataIndex: 'sponsorName',
        key: 'sponsorId',
        sorter: (a: ISubjectSponsor, b: ISubjectSponsor) =>
          a?.sponsorName?.localeCompare(b?.sponsorName, 'pt', {
            sensitivity: 'base'
          }),
        width: '25%',
        editable: true,
        options: {
          data: staticDataStore?.sponsors,
          value: 'sponsorId',
          name: 'name'
        }
      },
      {
        title: 'Ações',
        key: 'action',
        render: (_: any, record: ISubjectSponsor) => {
          const editable = isEditing(record);

          return (
            <Space size="middle">
              {editable ? (
                <span>
                  <Typography.Link
                    onClick={() => saveEdition(record)}
                    style={{ marginRight: 8 }}
                  >
                    Salvar
                  </Typography.Link>
                  <Popconfirm
                    title="Tem certeza que deseja cancelar a edição?"
                    onConfirm={cancelEdition}
                  >
                    <Typography.Link>Cancelar</Typography.Link>
                  </Popconfirm>
                </span>
              ) : (
                <>
                  <Typography.Link
                    disabled={editingRow !== null}
                    onClick={() => editRow(record)}
                  >
                    Editar
                  </Typography.Link>
                  <Popconfirm
                    title={`Tem certeza que deseja remover o patrocínio ${record.sponsorName} da editoria ${record.subjectName}?`}
                    onConfirm={() => handleRemoveSubjectSponsor(record)}
                    cancelText="Cancelar"
                    okText="Sim"
                  >
                    <Typography.Link>Remover</Typography.Link>
                  </Popconfirm>
                </>
              )}
            </Space>
          );
        },
        width: '15%'
      }
    ];
  }, [
    cancelEdition,
    editRow,
    editingRow,
    handleRemoveSubjectSponsor,
    isEditing,
    saveEdition,
    staticDataStore?.languages,
    staticDataStore?.sponsorCountries,
    staticDataStore?.sponsors,
    staticDataStore?.subjects
  ]);

  const mergedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: ISubjectSponsor) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            options: col.options
          })
        };
      }),
    [columns, isEditing]
  );

  const content = {
    [tabKeys.newSubjectSponsor]: (
      <Form
        form={form}
        labelCol={{ span: 2 }}
        labelAlign="left"
        wrapperCol={{ span: 8 }}
        layout="horizontal"
        validateMessages={validateMessages}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="sponsorId"
            label="Patrocinador"
            rules={[{ required: true }]}
          >
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.sponsors?.map((sponsor: ISponsor) => (
                <Option key={sponsor.sponsorId} value={sponsor.sponsorId}>
                  {sponsor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="subjectId"
            label="Editoria"
            rules={[{ required: true }]}
          >
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.subjects?.map((subject: ISubject) => (
                <Option key={subject.subjectId} value={subject.subjectId}>
                  {subject.pt}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item name="country" label="País" rules={[{ required: true }]}>
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.sponsorCountries?.map((country: ICountry) => (
                <Option key={country.country} value={country.country}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginBottom: 16 }}>
          <Form.Item
            name="language"
            label="Idioma"
            rules={[{ required: true }]}
          >
            <Select maxLength={100} disabled={loading}>
              {staticDataStore?.languages?.map((language: ILanguage) => (
                <Option key={language.language} value={language.language}>
                  {language.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div style={{ marginTop: 45 }}>
          <Button
            type="primary"
            size="large"
            disabled={submitting}
            loading={submitting}
            htmlType="submit"
          >
            Criar patrocínio de editoria
          </Button>
        </div>
      </Form>
    ),
    [tabKeys.listSubjectSponsors]: (
      <Form
        form={form}
        layout="horizontal"
        autoComplete="off"
        validateMessages={validateMessages}
        disabled={submitting}
      >
        <Table
          dataSource={data}
          columns={mergedColumns}
          components={{ body: { cell: SubjectSponsorCell } }}
          showSorterTooltip={false}
          rowClassName="editable-row"
          rowKey="subjectSponsorId"
          loading={loading || submitting}
          pagination={{ defaultPageSize: 50 }}
          bordered
          locale={{
            emptyText: () => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>Nenhuma informação encontrada</span>}
              />
            )
          }}
        />
      </Form>
    )
  };

  useEffect(() => {
    if (!staticDataStore.initialized) staticDataStore.reset();
  }, [staticDataStore]);

  useEffect(() => {
    if (!store.initialized) store.reset();
  }, [store]);

  useEffect(() => {
    if (!sdInitialized) {
      return;
    }

    const fetchData = (): ISubjectSponsor[] =>
      subjectSponsors?.map((subjectSponsor) => {
        const language = staticDataStore.getLanguageName(
          subjectSponsor.language
        );
        const country = staticDataStore.getCountryName(subjectSponsor.country);

        return {
          ...subjectSponsor,
          country,
          language
        };
      });

    setData(fetchData());
  }, [sdInitialized, staticDataStore, subjectSponsors]);

  if (!userStore.isAdmin) {
    return (
      <div style={{ margin: 32 }}>
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div style={{ color: 'red', marginTop: 32 }}>
          Essa página está disponível apenas para Administradores.
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: 32 }}>
      <Button
        icon={<ArrowLeftIcon />}
        size="large"
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>

      {error || sdError ? (
        <div style={{ color: 'red', marginTop: 32 }}>
          Erro no carregamento das informações.
        </div>
      ) : (!initialized && loading) || !data ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 32 }}>
          <Card
            style={{ width: '100%' }}
            tabList={[
              {
                key: tabKeys.listSubjectSponsors,
                tab: (
                  <span>
                    <UserListIcon />
                    Patrocínio de editorias
                  </span>
                )
              },
              {
                key: tabKeys.newSubjectSponsor,
                tab: (
                  <span>
                    <SponsorIcon />
                    Novo patrocínio de editoria
                  </span>
                )
              }
            ]}
            activeTabKey={activeTab}
            onTabChange={(key) => onTabChange(key)}
          >
            {content[activeTab]}
          </Card>
        </div>
      )}
      <div style={{ height: 32 }} />
    </div>
  );
});

export default SubjectSponsor;
