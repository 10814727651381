const showReadLang = (lang) => {
  if (lang === 'pt') {
    return 'Leia a notícia na íntegra';
  }

  if (lang === 'en') {
    return 'Read the full news';
  }

  if (lang === 'es') {
    return 'Lea la noticia en su totalidad';
  }

  if (lang === 'fr') {
    return 'Lisez la nouvelle à l’intégralité';
  }

  if (lang === 'it') {
    return 'Leggi tutta la notizia';
  }

  return null;
};

export default showReadLang;
