import { makeAutoObservable } from 'mobx';
import { UserAdminService } from 'services';

class UserAdminStoreMobx {
  adminUsers = [];

  initialized = false;

  loading = true;

  error = false;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  reset = async () => {
    try {
      this.loading = true;
      this.error = false;

      this.adminUsers = await UserAdminService.list();

      this.initialized = true;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.error = true;
      console.log(e);
    }
  };

  create = async ({ email, pwd, name, master, reports }) => {
    const adminUser = await UserAdminService.create({
      email,
      pwd,
      name,
      master,
      reports
    });
    await this.reset();
    return adminUser;
  };

  deactivate = async ({ userId }) => {
    await UserAdminService.deactivate({ userId });
    await this.reset();
  };

  activate = async ({ userId }) => {
    await UserAdminService.activate({ userId });
    await this.reset();
  };

  list = async () => {
    try {
      await UserAdminService.list();
    } catch (e) {
      console.error('falha ao listar administradores', e);
    }
  };
}

let singleton = null;

export const UserAdminStore = () => {
  if (!singleton) {
    singleton = new UserAdminStoreMobx();
  }
  return singleton;
};
