import { TvConfigListResponse, TvConfigUpdateRequest } from 'models';
import { Network, ServiceError } from './commons';

export const TvConfigService = {
  query: async () => {
    try {
      const { data } = await Network.get<TvConfigListResponse>(
        '/admin/tv_config'
      );
      return data.data;
    } catch (e) {
      throw new ServiceError('tvConfig.query', e);
    }
  },
  update: async (data: TvConfigUpdateRequest[]) => {
    try {
      await Network.put('admin/tv_config', { config: data });
    } catch (e) {
      throw new ServiceError('tvConfig.update', e);
    }
  }
};
