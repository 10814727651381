import React from 'react';
import { Button } from 'antd';
import './styles.css';

const Error = () => (
  <section className="bg--main">
    <article className="error--description">
      <h1>Erro 404</h1>
      <p>Essa página não existe :(</p>

      <Button href="/" type="primary" size="large">
        Ir para o início
      </Button>
    </article>
  </section>
);

export default Error;
