import React, { useState, useEffect } from 'react';
import { Form, Button, Input } from 'antd';
import { NewsEditorStore } from 'stores';
import Loading from '../Loading';

import './styles.css';

const LatestImages = ({ onCancel }) => {
  const [form] = Form.useForm();

  const [latestImages, setLatestImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [errorLoadImages, setErrorLoadImages] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);

  const handleConfirmSelection = () => {
    NewsEditorStore().setImage(
      selectedImage.thumbUrl,
      selectedImage.imageUrl,
      description
    );
    onCancel();
  };

  const handleClearSelected = () => {
    form.resetFields();
    setSelectedImage({});
  };

  useEffect(() => {
    const getLatestImages = async () => {
      try {
        const { data } = await NewsEditorStore().getLatestImages();
        setLoading(false);
        return setLatestImages([...data]);
      } catch (error) {
        setLoading(false);
        return setErrorLoadImages(true);
      }
    };

    getLatestImages();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      imageDescription: NewsEditorStore().imageDescription
    });
  }, [form]);

  return loading ? (
    <Loading />
  ) : (
    <>
      {!errorLoadImages ? (
        <ul className="latestImages">
          {latestImages.map((latestImage) => {
            const { imageUrl, thumbUrl } = latestImage;
            return (
              <li
                className="latestImages--item"
                key={`${imageUrl}.${thumbUrl}`}
              >
                <div
                  className={`latestImages--thumb ${
                    selectedImage?.thumbUrl === thumbUrl &&
                    'latestImages--destac'
                  }`}
                  style={{
                    backgroundImage: `url(${thumbUrl})`
                  }}
                  onClick={() =>
                    setSelectedImage({
                      imageUrl,
                      thumbUrl
                    })
                  }
                  aria-hidden="true"
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <p>Houve um erro ao carregar as últimas imagens.</p>
      )}

      <Form form={form}>
        <Form.Item name="imageDescription">
          <Input
            type="text"
            placeholder="Insira uma descrição para a imagem"
            value={description}
            maxLength={200}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>
      </Form>

      <div className="latestImages--wrapperButtons">
        <Button size="large" onClick={handleClearSelected}>
          Limpar seleção
        </Button>
        <Button
          type="primary"
          size="large"
          disabled={!selectedImage}
          onClick={handleConfirmSelection}
        >
          Confirmar
        </Button>
      </div>
    </>
  );
};

export default LatestImages;
