import React from 'react';

import { Modal } from 'antd';
import { ExclamationCircleOutlined as ExclamationIcon } from '@ant-design/icons';
import { NewsEditorStore } from 'stores';

const { confirm } = Modal;

const handleDeleteNews = () => {
  confirm({
    title: 'Tem certeza que deseja excluir?',
    icon: <ExclamationIcon />,
    content: `[${NewsEditorStore().language}] ${NewsEditorStore().title}`,
    okText: 'Sim',
    cancelText: 'Não',
    okType: 'danger',
    width: 630,
    onOk: () => NewsEditorStore().deleteSelectedNews()
  });
};

export default handleDeleteNews;
