import React from 'react';
import { observer } from 'mobx-react';
import { NewsEditorStore } from 'stores';

const PreviewPushData = observer(() => {
  const { loading, pushCount, openedByPush, openedByApp, shareCount } =
    NewsEditorStore();

  return !loading ? (
    <div className="preview--pushdata">
      <table>
        <tbody>
          <tr>
            <th>Notificações</th>
            <th>Compartilhamentos</th>
          </tr>
          <tr>
            <td>{pushCount || '0'}</td>
            <td>{shareCount || '0'}</td>
          </tr>
          <tr>
            <th>Aberturas via Push</th>
            <th>Aberturas via App</th>
          </tr>
          <tr>
            <td>{openedByPush || '0'}</td>
            <td>{openedByApp || '0'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
});

export default PreviewPushData;
