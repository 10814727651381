import { NewsReportListRequest, NewsReportListResponse } from 'models';
import { Network, ServiceError } from './commons';

export const NewsReportService = {
  getReport: async ({
    createdAtStart,
    createdAtEnd,
    language,
    subjectId,
    sponsorId,
    excluded
  }: NewsReportListRequest) => {
    try {
      const { data } = await Network.get<NewsReportListResponse>(
        'admin/reports/news',
        {
          params: {
            createdAtStart,
            createdAtEnd,
            language,
            subjectId,
            sponsorId,
            excluded
          }
        }
      );

      return data;
    } catch (e) {
      throw new ServiceError('news.index', e);
    }
  }
};
