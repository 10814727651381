import { Network, ServiceError } from './commons';

export const RSSService = {
  query: async () => {
    try {
      const { data } = await Network.get('/admin/v2/rss_config');
      return data.data;
    } catch (e) {
      throw new ServiceError('rss.query', e);
    }
  },
  patch: async (rssConfigId, data) => {
    try {
      await Network.patch(`admin/v2/rss_config/${rssConfigId}`, data);
    } catch (e) {
      throw new ServiceError('rss.patch', e);
    }
  }
};
