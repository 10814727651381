import { makeAutoObservable, toJS } from 'mobx';
import { RSSService } from 'services';

class RSSStoreMobx {
  configs = [];

  initialized = false;

  loading = true;

  error = false;

  selectedConfig = null;

  selectedWeekDayNumber = new Date().getDay();

  selectedConfigIndex = -1;

  constructor() {
    makeAutoObservable(this);
  }

  *reset() {
    try {
      this.loading = true;
      this.error = false;

      this.configs = yield RSSService.query();

      if (this.configs.length) {
        this.selectConfig(
          this.selectedConfigIndex !== -1 ? this.selectedConfigIndex : 0
        );
      }

      this.initialized = true;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.error = true;
      console.log(e);
    }
  }

  selectConfig = (index) => {
    this.selectedConfigIndex = index;
    this.selectedConfig = JSON.parse(JSON.stringify(toJS(this.configs[index])));
  };

  selectWeekDay = (weekDay) => {
    this.selectedWeekDayNumber = weekDay;
  };

  setSlogan = (index, value) => {
    this.selectedWeekDay.slogans[index].slogan = value;
  };

  get isChanged() {
    if (this.selectedConfigIndex === -1) {
      return false;
    }
    return (
      JSON.stringify(toJS(this.selectedConfig)) !==
      JSON.stringify(toJS(this.configs[this.selectedConfigIndex]))
    );
  }

  get selectedWeekDay() {
    return this.selectedConfig?.weekDays?.filter(
      ({ weekDay }) => weekDay === this.selectedWeekDayNumber
    )[0];
  }

  *save() {
    this.loading = true;
    try {
      const data = {
        slogans: this.selectedConfig.weekDays.flatMap(({ weekDay, slogans }) =>
          slogans
            .filter(({ slogan }) => !!slogan)
            .map(({ utcTimeStart, utcTimeEnd, slogan }) => ({
              weekDay,
              utcTimeStart,
              utcTimeEnd,
              slogan
            }))
        )
      };

      yield RSSService.patch(this.selectedConfig.rssConfigId, data);
      this.reset();
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }
}

let singleton = null;

export const RSSStore = () => {
  if (!singleton) {
    singleton = new RSSStoreMobx();
  }
  return singleton;
};
