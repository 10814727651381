import { makeAutoObservable } from 'mobx';
import { ITvConfig, TvConfigUpdateRequest } from 'models';
import { TvConfigService } from 'services';

class TvConfigStoreMobx {
  configs: ITvConfig[] = [];

  initialized = false;

  loading = true;

  error = false;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  async reset() {
    try {
      this.loading = true;
      this.error = false;

      this.configs = await TvConfigService.query();

      this.initialized = true;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.error = true;
      console.log(e);
    }
  }

  async update(data: ITvConfig[]) {
    this.loading = true;

    const ids = data.map((o) => o.utcStartTime);
    const selectedForUpdate: TvConfigUpdateRequest[] = this.configs
      .filter((el) => ids.indexOf(el.utcStartTime) === -1)
      .concat(data)
      .map(
        (i) =>
          ({
            utcStartTime: i.utcStartTime,
            utcEndTime: i.utcEndTime,
            adId: i.adId
          } as TvConfigUpdateRequest)
      );

    try {
      await TvConfigService.update(selectedForUpdate);
      await this.reset();
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }
}

let singleton: TvConfigStoreMobx | null = null;

export const TvConfigStore = () => {
  if (!singleton) {
    singleton = new TvConfigStoreMobx();
  }
  return singleton;
};
