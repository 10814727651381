import { Network, ServiceError } from './commons';

export const PushReportService = {
  getPushReportBySponsorAndDate: async ({
    sponsorId,
    createdAtStart,
    createdAtEnd
  }) => {
    try {
      const { data } = await Network.get('admin/reports/pushes/sponsor', {
        params: {
          sponsorId,
          createdAtStart,
          createdAtEnd
        }
      });
      return data;
    } catch (e) {
      throw new ServiceError('news.index', e);
    }
  }
};
