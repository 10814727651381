import React from 'react';
import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import { UserStore } from 'stores';

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const { isLogged } = UserStore();
  return isLogged ? <Component {...rest} /> : <Navigate to="/" replace />;
});

export default PrivateRoute;
