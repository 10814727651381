import React from 'react';
import { Modal, Tabs } from 'antd';
import LatestImages from 'components/LatestImages';
import UploadImage from 'components/UploadImage';

const AddPhotoModal = ({ visible, onCancel }) => {
  const tabItems = [
    {
      label: 'Fazer upload',
      children: <UploadImage onCancel={onCancel} />,
      key: '1'
    },
    {
      label: 'Selecionar existente',
      children: <LatestImages onCancel={onCancel} />,
      key: '2'
    }
  ];

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Foto"
      onCancel={onCancel}
      width={860}
      bodyStyle={{ minHeight: '500px' }}
      cancelText="Cancelar"
      okText="Confirmar"
      footer={null}
      forceRender
    >
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Modal>
  );
};

export default AddPhotoModal;
