/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  Button,
  Form,
  Input,
  Switch,
  message,
  Table,
  Tag,
  Space,
  Popconfirm
} from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  UserAddOutlined as UserAddIcon,
  UnorderedListOutlined as UserListIcon
} from '@ant-design/icons';
import { UserStore, UserAdminStore } from 'stores';

import Loading from '../../components/Loading';

const tagsColors = {
  administrador: 'blue',
  desativado: 'volcano',
  relatórios: 'gold',
  'usuário-logado': 'green'
};

const validateMessages = {
  required: '${label} é um campo obrigatório!',
  types: {
    email: '${label} não é um email válido!'
  }
};

const tabKeys = {
  listUsers: 'list-users',
  newUser: 'new-user'
};

const UserAdmin = observer(() => {
  const [submitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(tabKeys.listUsers);

  const userStore = UserStore();
  const store = UserAdminStore();
  const {
    loading,
    initialized,
    error,
    adminUsers,
    create,
    deactivate,
    activate
  } = store;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onTabChange = (key) => setActiveTab(key);

  const handleDisableUser = useCallback(
    async (user) => {
      try {
        await deactivate({ userId: user.admUserId });
        message.success(`Usuário ${user.name} desativado com sucesso!`, 5);
      } catch (e) {
        message.error(
          `Falha ao desativar usuário ${user.name}: ${e.message}`,
          5
        );
      }
    },
    [deactivate]
  );

  const handleActivateUser = useCallback(
    async (user) => {
      try {
        await activate({ userId: user.admUserId });
        message.success(`Usuário ${user.name} ativado com sucesso!`, 5);
      } catch (e) {
        message.error(`Falha ao ativar usuário ${user.name}: ${e.message}`, 5);
      }
    },
    [activate]
  );

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      await create({
        email: values.email,
        pwd: values.password,
        name: values.name,
        master: false,
        reports: !!values.hasReports
      });
      message.success('Novo usuário criado com sucesso!', 5);
      setActiveTab(tabKeys.listUsers);
    } catch (e) {
      const errorMessage = e?.data?.message || e.message;
      message.error(`Falha ao criar novo usuário: ${errorMessage}`, 5);
    } finally {
      setSubmitting(false);
    }
  };

  const dataSource = useMemo(() => {
    return adminUsers?.map((adminUser) => {
      const tags = [];
      if (userStore.user.name === adminUser.name) tags.push('usuário-logado');
      if (adminUser?.master) tags.push('administrador');
      if (!adminUser?.active) tags.push('desativado');
      if (adminUser?.reports) tags.push('relatórios');

      return { ...adminUser, tags: tags.sort() };
    });
  }, [adminUsers, userStore.user.name]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
        sorter: (a, b) =>
          a.name.localeCompare(b.name, 'pt', { sensitivity: 'base' }),
        width: '15%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) =>
          a.name.localeCompare(b.name, 'pt', { sensitivity: 'base' }),
        width: '30%'
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        render: (_, { tags }) => (
          <>
            {tags?.map((tag) => {
              return (
                <Tag color={tagsColors[tag.toLowerCase()]} key={tag}>
                  {tag.toLowerCase()}
                </Tag>
              );
            })}
          </>
        ),
        width: '25%'
      },
      {
        title: 'Ações',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            {/* <a>Editar</a> */}
            {record?.active ? (
              <Popconfirm
                title={`Tem certeza que deseja desativar ${record.name}?`}
                onConfirm={() => handleDisableUser(record)}
                cancelText="Cancelar"
                okText="Sim"
              >
                <a>Desativar {record.name}</a>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={`Tem certeza que deseja ativar ${record.name}?`}
                onConfirm={() => handleActivateUser(record)}
                cancelText="Cancelar"
                okText="Sim"
              >
                <a>Ativar {record.name}</a>
              </Popconfirm>
            )}
          </Space>
        )
      }
    ];
  }, [handleDisableUser, handleActivateUser]);

  const content = {
    [tabKeys.newUser]: (
      <>
        <Form
          form={form}
          labelCol={{ span: 2 }}
          labelAlign="left"
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          validateMessages={validateMessages}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <div style={{ marginBottom: 16 }}>
            <Form.Item name="name" label="Nome" rules={[{ required: true }]}>
              <Input
                name="name"
                maxLength={100}
                disabled={loading}
                placeholder="Nome"
              />
            </Form.Item>
          </div>

          <div style={{ marginBottom: 16 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input
                name="email"
                maxLength={100}
                disabled={loading}
                placeholder="Email"
              />
            </Form.Item>
          </div>

          <div style={{ marginBottom: 16 }}>
            <Form.Item
              name="password"
              label="Senha"
              rules={[{ required: true }]}
            >
              <Input.Password
                name="password"
                maxLength={100}
                disabled={loading}
                placeholder="Senha"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="hasReports"
            wrapperCol={{ span: 10 }}
            label="Relatórios"
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>

          <div style={{ marginTop: 45 }}>
            <Button
              type="primary"
              size="large"
              disabled={submitting}
              loading={submitting}
              htmlType="submit"
            >
              Criar usuário
            </Button>
          </div>
        </Form>
      </>
    ),
    [tabKeys.listUsers]: (
      <Table
        dataSource={dataSource}
        columns={columns}
        showSorterTooltip={false}
        rowKey="admUserId"
        loading={loading}
        pagination={{ defaultPageSize: 50 }}
        bordered
      />
    )
  };

  useEffect(() => {
    store.reset();
  }, [store]);

  if (!userStore.isAdmin) {
    return (
      <div style={{ margin: 32 }}>
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div style={{ color: 'red', marginTop: 32 }}>
          Essa página está disponível apenas para Administradores.
        </div>
      </div>
    );
  }

  return (
    <div style={{ margin: 32 }}>
      <Button
        icon={<ArrowLeftIcon />}
        size="large"
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>

      {error ? (
        <div style={{ color: 'red', marginTop: 32 }}>
          Erro no carregamento das informações.
        </div>
      ) : !initialized && loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 32 }}>
          <Card
            style={{ width: '100%' }}
            tabList={[
              {
                key: tabKeys.listUsers,
                tab: (
                  <span>
                    <UserListIcon />
                    Usuários
                  </span>
                )
              },
              {
                key: tabKeys.newUser,
                tab: (
                  <span>
                    <UserAddIcon />
                    Novo usuário
                  </span>
                )
              }
            ]}
            activeTabKey={activeTab}
            onTabChange={(key) => onTabChange(key)}
          >
            {content[activeTab]}
          </Card>
        </div>
      )}
      <div style={{ height: 32 }} />
    </div>
  );
});

export default UserAdmin;
