/* eslint-disable no-restricted-syntax */
import { makeAutoObservable, runInAction } from 'mobx';
import { StaticDataService } from 'services';

class StaticDataStoreMobx {
  initialized = false;

  waiting = false;

  languages = [];

  countries = [];

  sponsorCountries = [
    { country: 'BR', name: 'Brasil' },
    { country: 'US', name: 'Estados Unidos' },
    { country: 'PT', name: 'Portugal' },
    { country: 'GB', name: 'Reino Unido' },
    { country: 'MX', name: 'México' }
  ];

  subjects = [];

  sponsors = [];

  constructor() {
    makeAutoObservable(this);
  }

  ckeckIfItsInitialized = () => {
    if (!this.initialized) {
      throw new Error('Uninitialized StaticDataStore');
    }
  };

  reset = async () => {
    this.waiting = true;
    const result = await StaticDataService.index();
    const { languages, countries, subjects, sponsors } = result;
    runInAction(() => {
      this.languages = languages;
      this.countries = countries;
      this.subjects = subjects.sort((a, b) => {
        if (a.pt < b.pt) return -1;
        if (a.pt > b.pt) return 1;
        return 0;
      });
      this.sponsors = sponsors;
      this.waiting = false;
      this.initialized = true;
    });
  };

  getLanguageName = (language) => {
    this.ckeckIfItsInitialized();

    for (const lang of this.languages) {
      if (language === lang.language) {
        return lang.name;
      }
    }
    return language;
  };

  getCountryName = (country) => {
    this.ckeckIfItsInitialized();

    for (const _country of this.countries) {
      if (country === _country.country) {
        return _country.name;
      }
    }
    return country;
  };

  getSponsorCountryName = (country) => {
    this.ckeckIfItsInitialized();

    for (const _country of this.sponsorCountries) {
      if (country === _country.country) {
        return _country.name;
      }
    }
    return country;
  };

  getSubjectName = (subjectId, language) => {
    this.ckeckIfItsInitialized();

    for (const subject of this.subjects) {
      if (subjectId === subject.subjectId) {
        return subject[language] || subjectId;
      }
    }
    return subjectId;
  };

  getSponsorData = (sponsorId) => {
    this.ckeckIfItsInitialized();

    for (const sponsor of this.sponsors) {
      if (sponsorId === sponsor.sponsorId) {
        return sponsor;
      }
    }
    return {};
  };
}

let singleton = null;

export const StaticDataStore = () => {
  if (!singleton) {
    singleton = new StaticDataStoreMobx();
  }
  return singleton;
};
