import { Network } from './commons';

export const UserReportNewService = {
  getFilters: async () => {
    const { data } = await Network.get('admin/v2/reports/users/filters');
    return data;
  },
  getReport: async ({
    createdAtStart,
    createdAtEnd,
    lastAccessStart,
    lastAccessEnd,
    emailType,
    birthYearStart,
    birthYearEnd,
    gender,
    language,
    os,
    country,
    state,
    city,
    reportType
  }) => {
    const { data } = await Network.get('admin/v2/reports/users', {
      params: {
        createdAtStart: createdAtStart || undefined,
        createdAtEnd: createdAtEnd || undefined,
        lastAccessStart: lastAccessStart || undefined,
        lastAccessEnd: lastAccessEnd || undefined,
        emailType: emailType || undefined,
        birthYearStart: birthYearStart || undefined,
        birthYearEnd: birthYearEnd || undefined,
        gender: gender || undefined,
        language: language || undefined,
        os: os || undefined,
        country: country || undefined,
        state: state || undefined,
        city: city || undefined,
        reportType
      }
    });
    return data;
  }
};
