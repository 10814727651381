import React from 'react';
import { observer } from 'mobx-react';

import NewsList from 'components/NewsList';
import Preview from 'components/Preview';
import PreviewEmpty from 'components/PreviewEmpty';
import Sidebar from 'components/Sidebar';

import { NewsEditorStore } from 'stores';

const Home = observer(() => {
  return (
    <>
      <div className="container">
        <aside className="sidebar">
          <Sidebar />
        </aside>

        <section className="newsList--container">
          <NewsList />
        </section>

        <section className="container-preview">
          {NewsEditorStore().newsId ? (
            <Preview canEdit news={NewsEditorStore()} />
          ) : (
            <PreviewEmpty />
          )}
        </section>
      </div>
    </>
  );
});

export default Home;
