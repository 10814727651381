import React, { useState } from 'react';
import { observer } from 'mobx-react';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { useNavigate } from 'react-router-dom';
import { DatePicker, Button, Select, notification, Table, Input } from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  FrownOutlined as FrownIcon
} from '@ant-design/icons';
import { StaticDataStore } from 'stores';
import { NewsReportService } from 'services';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Loading from '../../components/Loading';
import { ILanguage, INewsReport } from 'models';

const dateFormat = 'YYYY-MM-DD';
const parseEmpty = (value: any) => (value === '' ? undefined : value);

const firstDayOfCurrentMonth = (() => {
  const aux = new Date();
  aux.setDate(1);
  return moment(aux);
})();

const columns = [
  {
    title: 'Data de criação',
    dataIndex: 'createdAt'
  },
  {
    title: 'Título',
    dataIndex: 'title'
  },
  {
    title: 'Editoria',
    dataIndex: 'subjectId'
  },
  {
    title: 'Notificações',
    dataIndex: 'sentPush'
  },
  {
    title: 'Abertos via Push',
    dataIndex: 'openedByPush'
  },
  {
    title: 'Abertos via App',
    dataIndex: 'openedByApp'
  },
  {
    title: 'Abertos Total',
    dataIndex: 'openedTotal'
  },
  {
    title: 'Compartilhamentos',
    dataIndex: 'shares'
  }
];

const NewsReport = observer(() => {
  const navigate = useNavigate();

  const { languages, sponsors } = StaticDataStore();

  const [language, setLanguage] = useState();
  const [excluded, setExcluded] = useState('');
  const [subjectId] = useState('');
  const [sponsorId, setSponsorId] = useState(null);

  const [createdAtStart, setCreatedAtStart] = useState<
    moment.Moment | null | undefined
  >(firstDayOfCurrentMonth);
  const [createdAtEnd, setCreatedAtEnd] = useState<
    moment.Moment | null | undefined
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<INewsReport[] | null>(null);
  const [separator, setSeparator] = useState(';');

  const onGenerateReportClick = async () => {
    setIsLoading(true);
    setData(null);
    try {
      if (!createdAtStart) {
        notification.error({
          message: 'É preciso informar a data inicial.',
          duration: 5
        });
        return;
      }
      setData(
        await NewsReportService.getReport({
          createdAtStart: createdAtStart.format(dateFormat),
          createdAtEnd: moment.isMoment(createdAtEnd)
            ? createdAtEnd.format(dateFormat)
            : undefined,
          language: parseEmpty(language),
          subjectId: parseEmpty(subjectId),
          sponsorId: parseEmpty(sponsorId),
          excluded: parseEmpty(excluded)
        })
      );
    } catch (e: any) {
      notification.open({
        message: e.message,
        description: e.data?.message,
        duration: 0,
        icon: <FrownIcon style={{ color: '#ED0601' }} />
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        margin: 32,
        backgroundColor: 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 8
        }}
      >
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div>
          {/* <Select
            showSearch
            style={{ width: 180, marginLeft: 16 }}
            value={subjectId}
            onChange={(value) => setSubjectId(value)}
          >
            <Select.Option value="">Editoria</Select.Option>
            {subjects.map(({ subjectId: id, pt }) => (
              <Select.Option key={id} value={id}>
                {pt}
              </Select.Option>
            ))}
          </Select> */}

          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data inicial"
            value={createdAtStart}
            onChange={(value) => {
              setCreatedAtStart(value);
            }}
          />
          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data final"
            value={createdAtEnd}
            onChange={(value) => setCreatedAtEnd(value)}
          />

          <Select
            allowClear
            style={{ width: 180, marginLeft: 16 }}
            value={language}
            onChange={(value) => setLanguage(value)}
            placeholder="Idioma"
          >
            {languages.map(({ language: lang, name }: ILanguage) => (
              <Select.Option key={lang} value={lang}>
                {name}
              </Select.Option>
            ))}
          </Select>

          <Select
            allowClear
            style={{ width: 180, marginLeft: 16 }}
            value={sponsorId}
            onChange={(value) => setSponsorId(value)}
            placeholder="Oferecedor"
          >
            {sponsors.map(
              ({
                sponsorId: sponsorId_,
                name
              }: {
                sponsorId: number;
                name: string;
              }) => (
                <Select.Option key={sponsorId_} value={sponsorId_}>
                  {name}
                </Select.Option>
              )
            )}
          </Select>

          <Select
            style={{ width: 200, marginLeft: 16 }}
            value={excluded}
            defaultActiveFirstOption
            onChange={(value) => setExcluded(value)}
          >
            <Select.Option key="excluded_all" value="">
              Todas as notícias
            </Select.Option>
            <Select.Option key="excluded_true" value={true}>
              Mostrar excluídas
            </Select.Option>
            <Select.Option key="excluded_false" value={false}>
              Mostrar não excluídas
            </Select.Option>
          </Select>
        </div>

        <Button
          type="primary"
          size="large"
          style={{ width: 150 }}
          onClick={onGenerateReportClick}
          disabled={isLoading}
        >
          Gerar relatório
        </Button>
      </div>
      <div style={{ marginTop: 32 }}>
        {isLoading && <Loading />}

        {data && !isLoading && (
          <>
            <h4>
              {!data.length &&
                'Nenhuma notícia encontrada com os critérios informados'}
              {data.length === 2 &&
                `1 notícia encontrada com os critérios informados`}
              {data.length > 1 &&
                `${(data.length - 1)
                  .toString()
                  .replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    '.'
                  )} notícias encontradas com os critérios informados`}
            </h4>
            {!!data.length && (
              <Table
                bordered
                dataSource={data}
                style={{ marginTop: 16 }}
                columns={columns}
              />
            )}
            {!!data.length && (
              <>
                <Input
                  type="text"
                  placeholder=""
                  value={separator}
                  maxLength={2}
                  style={{ width: 40, height: 40 }}
                  onChange={(e) => setSeparator(e.target.value)}
                />
                <Button
                  style={{ marginLeft: 16, marginTop: 16, marginBottom: 48 }}
                  size="large"
                >
                  <CSVLink
                    filename="relatorio-de-noticias.csv"
                    data={data}
                    headers={columns.map((item) => ({
                      label: item.title,
                      key: item.dataIndex
                    }))}
                    separator={separator}
                  >
                    {`Exportar para CSV usando "${separator}"`}
                  </CSVLink>
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default NewsReport;
