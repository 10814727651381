import { makeObservable, observable, computed, action } from 'mobx';
import { UserService, setAuthToken } from 'services';
import * as PubSub from 'pubsub-js';

class UserStoreMobx {
  user = {};

  constructor() {
    makeObservable(this, {
      user: observable,
      isLogged: computed,
      login: action,
      logout: action,
      reset: action
    });
    this.reset();
  }

  reset = async () => {
    try {
      const cachedUserStr = localStorage.getItem('user');
      const cachedUserObj = JSON.parse(cachedUserStr);
      if (typeof cachedUserObj === 'object') {
        this.user = cachedUserObj;
        setAuthToken(this.user?.token);
      }
    } catch (e) {
      this.user = null;
      console.log(e);
    }
  };

  get isLogged() {
    return !!this.user?.name;
  }

  get isAdmin() {
    return !!this.user?.master;
  }

  login = async ({ email, pwd, captcha }) => {
    const user = await UserService.login({
      email,
      pwd,
      captcha
    });
    localStorage.setItem('user', JSON.stringify(user));
    await this.reset();
    return this.user;
  };

  lightLogout = async () => {
    localStorage.clear();
    await this.reset();
  };

  logout = async () => {
    try {
      await UserService.logout();
    } catch (e) {
      console.error('falha no logout', e);
    }
    await this.lightLogout();
  };
}

let singleton = null;

export const UserStore = () => {
  if (!singleton) {
    singleton = new UserStoreMobx();

    PubSub.subscribe('UNAUTHORIZED_ACCESS', () => {
      singleton.lightLogout();
    });
  }
  return singleton;
};
