import React from 'react';
import { observer } from 'mobx-react';
import PreviewHeader from './PreviewHeader';
import PreviewBody from './PreviewBody';
import PreviewFooter from './PreviewFooter';
import PreviewPushData from './PreviewPushData';
import './styles.css';

const Preview = observer(({ canEdit, news }) => {
  return (
    <article>
      <PreviewHeader canEdit={canEdit} news={news} />
      <PreviewBody />
      <PreviewFooter news={news} />
      {!!canEdit && <PreviewPushData />}
    </article>
  );
});

export default Preview;
