import React from 'react';

import { Modal, Button } from 'antd';
import { StaticDataStore } from 'stores';

const AddSponsorModal = ({
  visible,
  onCancel,
  onOk,
  title,
  currentSponsor
}) => {
  const { sponsors } = StaticDataStore();

  return (
    <Modal
      destroyOnClose
      open={visible}
      title={title}
      onCancel={onCancel}
      width={800}
      footer={null}
      forceRender
    >
      <Button
        key="none"
        type={!currentSponsor ? 'primary' : 'default'}
        size="large"
        style={{ width: 150, marginRight: 20, marginBottom: 20 }}
        onClick={() => onOk(undefined)}
      >
        Nenhum
      </Button>

      <div>
        {sponsors.map(({ sponsorId, name }) => (
          <Button
            key={sponsorId}
            type={currentSponsor === sponsorId ? 'primary' : 'default'}
            size="large"
            style={{ width: 130, marginRight: 20, marginBottom: 20 }}
            onClick={() => onOk(sponsorId)}
          >
            {name}
          </Button>
        ))}
      </div>
    </Modal>
  );
};

export default AddSponsorModal;
