import React from 'react';
import logo from 'assets/app-logo-black.png';
import packageInfo from '../../../package.json';
import './styles.css';

const Logo = () => (
  <>
    <img src={logo} alt="AppNews Jovem Pan" />
    <span className="logo--version">{`versão ${packageInfo.adminVersion}`}</span>
  </>
);

export default Logo;
