/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { Form, Table, DatePicker, Select, Button } from 'antd';
import { ArrowLeftOutlined as ArrowLeftIcon } from '@ant-design/icons';
import { UserReportNewStore } from 'stores';

import Loading from '../../components/Loading';

import './styles.css';

const columnsUserReport = [
  {
    title: 'Data de registro',
    dataIndex: 'createdAt'
  },
  {
    title: 'Último acesso',
    dataIndex: 'lastOpen'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Ano de nascimento',
    dataIndex: 'birthYear'
  },
  {
    title: 'Gênero',
    dataIndex: 'genderId'
  },
  {
    title: 'Cidade',
    dataIndex: 'city'
  },
  {
    title: 'Estado',
    dataIndex: 'brState'
  },
  {
    title: 'País',
    dataIndex: 'country'
  },
  {
    title: 'Idioma',
    dataIndex: 'language'
  },
  {
    title: 'Sistema',
    dataIndex: 'os'
  }
];

const { YearPicker } = DatePicker;
const { Option } = Select;

const UserReportNew = observer(() => {
  const store = UserReportNewStore();
  const { initialized, loading, filterOptions, citiesByState } = store;
  const navigate = useNavigate();

  useEffect(() => {
    if (store.initializeError) {
      store.getFilters();
    }
  }, [store]);

  return (
    <div className="container--page">
      <Form className="formUserReport">
        <div className="formUserReport--row">
          <Button
            icon={<ArrowLeftIcon />}
            size="large"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </div>

        {store.initializeError ? (
          <div style={{ color: 'red', marginTop: 32 }}>
            {store.initializeError}
          </div>
        ) : !initialized ? (
          <Loading />
        ) : (
          <>
            <h1>Resumo</h1>
            <div>
              <strong>Usuários:</strong>
              <span
                style={{ marginLeft: 8 }}
              >{`${filterOptions.activeInstalls} (${filterOptions.installs} downloads)`}</span>
            </div>
            <div style={{ marginTop: 16 }}>
              <strong>Por idioma:</strong>
              {filterOptions?.languages?.map(({ language, nameCount }) =>
                language ? (
                  <span key={language} style={{ marginLeft: 8 }}>
                    {nameCount}
                  </span>
                ) : null
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <strong>Por país:</strong>
              {filterOptions?.countries?.map(({ country, nameCount }) =>
                country ? (
                  <span key={country} style={{ marginLeft: 8 }}>
                    {nameCount}
                  </span>
                ) : null
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <strong>Por estado:</strong>
              {filterOptions?.ufs?.map(({ uf, nameCount }) =>
                uf ? (
                  <span key={uf} style={{ marginLeft: 8 }}>
                    {nameCount}
                  </span>
                ) : null
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <strong>Por gênero:</strong>
              {filterOptions?.genders?.map(({ genderId, nameCount }) =>
                genderId && genderId !== 'NA' ? (
                  <span key={genderId} style={{ marginLeft: 8 }}>
                    {nameCount}
                  </span>
                ) : null
              )}
            </div>
            <div style={{ marginTop: 16 }}>
              <strong>Por sistema:</strong>
              {filterOptions?.oss?.map(({ osId, nameCount }) =>
                osId ? (
                  <span key={osId} style={{ marginLeft: 8 }}>
                    {nameCount}
                  </span>
                ) : null
              )}
            </div>
            <h1 style={{ marginTop: 24 }}>Relatório avançado</h1>
            <div className="formUserReport--row">
              <span>
                Cadastro entre
                <DatePicker
                  style={{ marginLeft: 16 }}
                  format="DD-MM-YYYY"
                  locale={locale}
                  placeholder="data inicial"
                  value={store.createdAtStart}
                  onChange={(value) => store.setCreatedAtStart(value)}
                />
                <DatePicker
                  style={{ marginLeft: 16, marginRight: 48 }}
                  format="DD-MM-YYYY"
                  locale={locale}
                  placeholder="data final"
                  value={store.createdAtEnd}
                  onChange={(value) => store.setCreatedAtEnd(value)}
                />
                Último acesso entre
                <DatePicker
                  placeholder="data inicial"
                  style={{ marginLeft: 16 }}
                  value={store.lastAccessStart}
                  onChange={(value) => store.setLastAccessStart(value)}
                />
                <DatePicker
                  placeholder="data final"
                  style={{ marginLeft: 16 }}
                  value={store.lastAccessEnd}
                  onChange={(value) => store.setLastAccessEnd(value)}
                />
              </span>
            </div>

            <div className="formUserReport--row">
              <Select
                showSearch
                style={{ width: 220 }}
                value={store.language}
                onChange={(value) => store.setLanguage(value)}
              >
                {filterOptions?.languages?.map(({ language, name }) => (
                  <Option key={language} value={language}>
                    {name}
                  </Option>
                ))}
              </Select>
              <Select
                showSearch
                style={{ width: 240 }}
                value={store.country}
                onChange={(value) => store.setCountry(value)}
              >
                {filterOptions?.countries?.map(({ country, name }) => (
                  <Option key={country} value={country}>
                    {name}
                  </Option>
                ))}
              </Select>

              <Select
                showSearch
                style={{ width: 240 }}
                value={store.state}
                onChange={(value) => store.setState(value)}
                disabled={store.country !== 'BR'}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {filterOptions?.ufs?.map(({ uf, name }) => (
                  <Option key={uf} value={uf}>
                    {name}
                  </Option>
                ))}
              </Select>

              <Select
                showSearch
                defaultValue="Cidade"
                style={{ width: 240 }}
                value={store.city}
                onChange={(value) => store.setCity(value)}
                disabled={store.country !== 'BR' || !store.state}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {citiesByState.map(({ brCityId, name }) => (
                  <Option key={brCityId} value={brCityId}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="formUserReport--row">
              <Select
                showSearch
                value={store.gender}
                onChange={(value) => store.setGender(value)}
                style={{ width: 220 }}
              >
                {filterOptions?.genders?.map(({ genderId, name }) => (
                  <Option key={genderId} value={genderId}>
                    {name}
                  </Option>
                ))}
              </Select>

              <span>
                Nascido entre
                <YearPicker
                  style={{ marginLeft: 16 }}
                  format="YYYY"
                  locale={locale}
                  placeholder="ano inicial"
                  value={store.birthYearStart}
                  onChange={(value) => store.setBirthYearStart(value)}
                />
                <YearPicker
                  style={{ marginLeft: 16 }}
                  format="YYYY"
                  locale={locale}
                  placeholder="ano final"
                  value={store.birthYearEnd}
                  onChange={(value) => store.setBirthYearEnd(value)}
                />
              </span>

              {/* <Select
                showSearch
                style={{ width: 240 }}
                value={store.emailType}
                onChange={(value) => store.setEmailType(value)}
              >
                {filterOptions?.emailsType?.map(({ emailTypeId, name }) => (
                  <Option key={emailTypeId} value={emailTypeId}>
                    {name}
                  </Option>
                ))}
              </Select> */}
              <Select
                showSearch
                style={{ width: 240 }}
                value={store.os}
                onChange={(value) => store.setOs(value)}
              >
                {filterOptions?.oss?.map(({ osId, name }) => (
                  <Option key={osId} value={osId}>
                    {name}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              style={{
                marginTop: 30
              }}
            >
              <Button
                style={{ marginRight: '32px' }}
                type="primary"
                size="large"
                disabled={store.loading}
                onClick={() => store.getReport(true)}
              >
                Calcular
              </Button>

              <Button
                style={{ marginRight: '16px' }}
                size="large"
                disabled={store.loading}
                onClick={() => {
                  if (!store.createdAtStart || !store.createdAtEnd) {
                    alert(
                      'Para obter a lista de emails, é necessário especificar a data inicial e final do cadastro.'
                    );
                    return;
                  }
                  if (
                    store.createdAtEnd.diff(store.createdAtStart, 'days') > 30
                  ) {
                    alert(
                      'Para obter a lista de emails, o intervalo entre a data inicial e final do cadastro não pode ser superior a 1 mês.'
                    );
                    return;
                  }
                  store.getReport(false);
                }}
              >
                Obter emails
              </Button>

              <Button
                style={{ marginLeft: '16px' }}
                disabled={store.loading}
                size="large"
                onClick={() => store.setEmptyState()}
              >
                Limpar
              </Button>
            </div>
          </>
        )}
      </Form>

      {loading ? (
        <Loading />
      ) : store.count !== undefined && store.count !== null ? (
        <>
          <div className="formUserReport--row">
            <span
              className="formUserReport--result"
              style={{ marginTop: 20, marginBottom: 8 }}
            >
              {store.count === 0
                ? 'Nenhum regitro encontrado'
                : store.count === 1
                ? `${store.count} regitro encontrado`
                : `${store.count
                    .toString()
                    .replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      '.'
                    )} registros encontrados`}
            </span>
          </div>
          {store?.data?.length ? (
            <>
              <Table
                bordered
                dataSource={store.data}
                columns={columnsUserReport}
              />
              <Button
                style={{ marginTop: 0, marginBottom: 48 }}
                disabled={false /* filteredUsers.length === 0 */}
                size="large"
              >
                <CSVLink filename="relatorio-usuarios.csv" data={store.data}>
                  Exportar para Excel
                </CSVLink>
              </Button>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
});

export default UserReportNew;
