import { Network, ServiceError } from './commons';

export const UserService = {
  login: async ({ email, pwd, captcha }) => {
    try {
      const { data } = await Network.post(
        'admin/user/login',
        {
          email,
          pwd,
          captcha
        },
        { withCredentials: true }
      );
      return data;
    } catch (e) {
      throw new ServiceError('user.login', e);
    }
  },
  logout: async () => {
    try {
      await Network.post('admin/user/logout');
    } catch (e) {
      throw new ServiceError('user.logout', e);
    }
  }
};
