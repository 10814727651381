import { makeAutoObservable, runInAction } from 'mobx';
import { NewsService } from 'services';

class NewsListStoreMobx {
  initialized = false;

  waiting = true;

  language = '';

  title = '';

  list = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = async () => {
    this.waiting = true;
    const { data } = await NewsService.query({
      language: this.language || undefined,
      title: this.title || undefined
    });
    runInAction(() => {
      this.list = data;
      this.waiting = false;
      this.initialized = true;
    });
  };

  setLanguage = async (language) => {
    this.language = language;
    this.reset();
  };

  setTitle = async (title) => {
    this.title = title;
    this.reset();
  };
}

let singleton = null;

export const NewsListStore = () => {
  if (!singleton) {
    singleton = new NewsListStoreMobx();
  }
  return singleton;
};
