import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import {
  RiLogoutBoxLine as LogoutIcon,
  RiFundsBoxLine as FundsIcon,
  RiFolderAddLine as FolderAddIcon,
  RiRssFill as RSSIcon,
  RiUserSettingsLine as UserIcon,
  RiAwardFill as SponsorIcon,
  RiTvLine as TvConfigIcon,
  RiRouterLine as TvScheduleIcon
} from 'react-icons/ri';
import {
  MdCropOriginal as NewsIcon,
  MdFilter as AllNewsIcon
} from 'react-icons/md';
import MenuItem from 'components/MenuItem';
import Logo from 'components/Logo';
import Loading from 'components/Loading';

import './styles.css';
import {
  UserStore,
  NewsListStore,
  NewsEditorStore,
  StaticDataStore
} from 'stores';

const Sidebar = observer(() => {
  const { user, logout } = UserStore();
  const { language, setLanguage, setTitle, waiting, title } = NewsListStore();

  const menuActive = (lang = '') => {
    setLanguage(lang);
  };

  useEffect(() => {
    if (!StaticDataStore().initialized) {
      StaticDataStore()
        .reset()
        .catch(() => {
          logout();
        });
    }
  }, [logout]);

  return (
    <>
      <div style={{ marginBottom: '12px' }}>
        <Logo />
      </div>

      <div
        style={{ textAlign: 'right', fontSize: '12px', marginBottom: '16px' }}
      >
        <p>
          olá, <span style={{ fontWeight: '700' }}>{user?.name}</span>
        </p>
      </div>

      {!StaticDataStore().initialized ? (
        <Loading />
      ) : (
        <>
          <div className="menu--container">
            <MenuItem
              icon={<AllNewsIcon />}
              description="Todas as notícias"
              active={!language && 'menu--itemActive'}
              onClick={() => menuActive()}
            />

            <MenuItem
              icon={<NewsIcon />}
              description="Português"
              active={language === 'pt' && 'menu--itemActive'}
              onClick={() => menuActive('pt')}
            />

            <MenuItem
              icon={<NewsIcon />}
              description="Inglês"
              active={language === 'en' && 'menu--itemActive'}
              onClick={() => menuActive('en')}
            />

            <MenuItem
              icon={<NewsIcon />}
              description="Espanhol"
              active={language === 'es' && 'menu--itemActive'}
              onClick={() => menuActive('es')}
            />

            <MenuItem
              icon={<NewsIcon />}
              description="Francês"
              active={language === 'fr' && 'menu--itemActive'}
              onClick={() => menuActive('fr')}
            />

            <MenuItem
              icon={<NewsIcon />}
              description="Italiano"
              active={language === 'it' && 'menu--itemActive'}
              onClick={() => menuActive('it')}
            />
          </div>

          <Input.Search
            name="title"
            maxLength={100}
            defaultValue={title}
            disabled={waiting}
            placeholder="Filtrar por título"
            onSearch={(value) => setTitle(value)}
            style={{ marginTop: 20, marginBottom: 20 }}
            allowClear
          />

          <div className="menu--container">
            <MenuItem
              icon={<FolderAddIcon />}
              itemDestac
              description="Adicionar notícia"
              onClick={() => NewsEditorStore().startNewNews()}
              path="adicionar-noticia"
            />
          </div>

          {user?.reports && (
            <div className="menu--container" style={{ marginTop: -2 }}>
              <MenuItem
                icon={<FundsIcon />}
                description="Relatório de usuários"
                onClick={() => {}}
                path="relatorio-de-usuarios"
              />

              {/* <MenuItem
                icon={<FundsIcon />}
                description="Relatório comercial"
                onClick={() => {}}
                path="relatorio-comercial"
              /> */}
              <MenuItem
                icon={<FundsIcon />}
                description="Pushes por oferecedor"
                onClick={() => {}}
                path="pushes-por-oferecedor"
              />
              <MenuItem
                icon={<FundsIcon />}
                description="Pushes enviados"
                onClick={() => {}}
                path="relatorio-pushes-enviados"
              />
              <MenuItem
                icon={<FundsIcon />}
                description="Relatório de notícias"
                onClick={() => {}}
                path="relatorio-de-noticias"
              />
            </div>
          )}

          {user?.master && (
            <>
              <div className="menu--container" style={{ marginTop: -2 }}>
                <MenuItem
                  icon={<RSSIcon />}
                  description="RSS"
                  onClick={() => {}}
                  path="rss"
                />
              </div>
              <div className="menu--container" style={{ marginTop: -2 }}>
                <MenuItem
                  icon={<UserIcon />}
                  description="Administrar usuários"
                  onClick={() => {}}
                  path="administrar-usuarios"
                />
              </div>
              <div className="menu--container" style={{ marginTop: -2 }}>
                <MenuItem
                  icon={<SponsorIcon />}
                  description="Patrocínio de editorias"
                  onClick={() => {}}
                  path="patrocinio-editorias"
                />
              </div>
              <div className="menu--container" style={{ marginTop: -2 }}>
                <MenuItem
                  icon={<TvConfigIcon />}
                  description="Configurações da TV"
                  onClick={() => {}}
                  path="configuracoes-tv"
                />
              </div>
              <div className="menu--container" style={{ marginTop: -2 }}>
                <MenuItem
                  icon={<TvScheduleIcon />}
                  description="Transmissões"
                  onClick={() => {}}
                  path="transmissoes"
                />
              </div>

              {/* <MenuItem
                icon={<FundsIcon />}
                description="Relatório comercial"
                onClick={() => {}}
                path="relatorio-comercial"
              /> */}
            </>
          )}

          <div className="menu--container">
            <MenuItem
              icon={<LogoutIcon />}
              description="Sair"
              onClick={() => logout()}
            />
          </div>
        </>
      )}
    </>
  );
});

export default Sidebar;
