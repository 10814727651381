import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { StaticDataStore, NewsEditorStore, UserStore } from 'stores';
import { observer } from 'mobx-react';
import Loading from 'components/Loading';

import './styles.css';

const PreviewApp = observer(() => {
  const { createdAt, title, subjectId, thumbUrl, language } = NewsEditorStore();
  const { logout } = UserStore();

  const dateCreatedAt = createdAt || new Date().toISOString();
  const formattedCreatedAt = new Date(dateCreatedAt);
  const formattedDate = format(formattedCreatedAt, 'dd/MMM HH:mm', {
    locale: ptBR
  });

  useEffect(() => {
    if (!StaticDataStore().initialized) {
      StaticDataStore()
        .reset()
        .catch(() => {
          logout();
        });
    }
  }, [logout]);

  return (
    <article className="previewApp">
      <div
        className="previewApp--thumb"
        style={{ backgroundImage: `url(${thumbUrl})` }}
      />
      <div className="previewApp--content">
        <span>
          {formattedDate} {subjectId ? ' - ' : ''}
          {!StaticDataStore().initialized ? (
            <Loading />
          ) : (
            StaticDataStore().getSubjectName(subjectId, language || 'pt')
          )}
        </span>
        <h2>{title}</h2>
      </div>
    </article>
  );
});

export default PreviewApp;
