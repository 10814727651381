import React, { useState } from 'react';

import { Modal, Checkbox, Divider } from 'antd';

import { NewsEditorStore, StaticDataStore } from 'stores';

import './styles.css';

const AddCountryModal = ({ visible, onCancel, onOk }) => {
  const [selectedCountries, setSelectedCountries] = useState(
    NewsEditorStore().countries
  );

  const ok = () => {
    NewsEditorStore().setCountries(selectedCountries);
    onOk();
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Selecione país/países"
      onCancel={onCancel}
      onOk={ok}
      width={800}
      cancelText="Cancelar"
      okText="Confirmar"
      cancelButtonProps={{ style: { display: 'none' } }}
      forceRender
    >
      <Checkbox
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedCountries([]);
          }
        }}
        checked={!selectedCountries?.length}
      >
        Todos os países
      </Checkbox>

      <Divider />

      <Checkbox.Group
        options={StaticDataStore().countries.map(({ country, name }) => ({
          label: name,
          value: country
        }))}
        value={selectedCountries}
        onChange={(list) => setSelectedCountries(list)}
      />
    </Modal>
  );
};

export default AddCountryModal;
