import axios from 'axios';
import { Network, ServiceError, setInterceptors } from './commons';

const REQUEST_TIMEOUT = 20000;
const BASE_CDN_URL = 'https://static.appnewsdelivery.net/';

const getPresignedUrl = async (filename, contentType) => {
  const { data } = await Network.post('admin/images/presigned_url', {
    filename,
    content_type: contentType
  });
  return data.url;
};

export const ImageService = {
  uploadImages: async (croppedImage, fullImage) => {
    const axiosInstance = axios.create({
      timeout: REQUEST_TIMEOUT
    });
    setInterceptors(axiosInstance);

    try {
      await Promise.all([
        axiosInstance.put(
          await getPresignedUrl(croppedImage.name, croppedImage.type),
          croppedImage,
          {
            headers: {
              'Content-Type': croppedImage.type,
              'Cache-Control': 'public,max-age=31536000,immutable',
              'x-amz-acl': 'public-read'
            }
          }
        ),
        axiosInstance.put(
          await getPresignedUrl(fullImage.name, fullImage.type),
          fullImage,
          {
            headers: {
              'Content-Type': fullImage.type,
              'Cache-Control': 'public,max-age=31536000,immutable',
              'x-amz-acl': 'public-read'
            }
          }
        )
      ]);
      return {
        croppedImageUrl: BASE_CDN_URL + croppedImage.name,
        fullImageUrl: BASE_CDN_URL + fullImage.name
      };
    } catch (e) {
      throw new ServiceError('news.index', e);
    }
  }
};
