import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { StaticDataStore, NewsEditorStore, UserStore } from 'stores';
import { handleDeleteNews } from 'components/Modals';

import { observer } from 'mobx-react';

import {
  RiDeleteBin7Line as DeleteIcon,
  RiEditLine as EditIcon
} from 'react-icons/ri';

const PreviewHeader = observer(({ canEdit, news }) => {
  const { subjectName, loading } = NewsEditorStore();
  const { logout } = UserStore();

  useEffect(() => {
    if (!StaticDataStore().initialized) {
      StaticDataStore()
        .reset()
        .catch(() => {
          logout();
        });
    }
  }, [logout]);

  return (
    <div className="preview--options">
      <p>{subjectName}</p>
      {loading ? null : (
        <>
          {canEdit && !news.deletedAt && (
            <div className="preview--edit">
              <DeleteIcon onClick={handleDeleteNews} />
              <Link to="/adicionar-noticia">
                <EditIcon />
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default PreviewHeader;
