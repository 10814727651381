import React, { useState } from 'react';
import { observer } from 'mobx-react';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { useNavigate } from 'react-router-dom';
import { DatePicker, Button, Select, notification, Table } from 'antd';
import {
  ArrowLeftOutlined as ArrowLeftIcon,
  FrownOutlined as FrownIcon
} from '@ant-design/icons';
import { StaticDataStore } from 'stores';
import { SentPushReportService } from 'services';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Loading from '../../components/Loading';
import { ILanguage, ISentPushReport } from 'models';

const dateFormat = 'YYYY-MM-DD';
const parseEmpty = (value: any) => (value === '' ? undefined : value);

const firstDayOfCurrentMonth = (() => {
  const aux = new Date();
  aux.setDate(1);
  return moment(aux);
})();

const columns = [
  {
    title: 'Data de criação',
    dataIndex: 'createdAt'
  },
  {
    title: 'Título',
    dataIndex: 'title'
  },
  // {
  //   title: 'Editoria',
  //   dataIndex: 'subjectId'
  // },
  {
    title: 'Notificações',
    dataIndex: 'total'
  },
  // {
  //   title: 'Recebidos',
  //   dataIndex: 'sentPushCount'
  // },
  {
    title: 'Aberturas via Push',
    dataIndex: 'openedByPush'
  },
  {
    title: 'Aberturas via App',
    dataIndex: 'openedByApp'
  },
  {
    title: 'Aberturas Total',
    dataIndex: 'opened'
  },
  {
    title: 'Compartilhamentos',
    dataIndex: 'shareCount'
  }
];

const SentPushReport = observer(() => {
  const navigate = useNavigate();

  const { languages } = StaticDataStore();

  const [language, setLanguage] = useState();
  const [excluded, setExcluded] = useState('');
  const [subjectId] = useState('');

  const [createdAtStart, setCreatedAtStart] = useState<
    moment.Moment | null | undefined
  >(firstDayOfCurrentMonth);
  const [createdAtEnd, setCreatedAtEnd] = useState<
    moment.Moment | null | undefined
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ISentPushReport[] | null>(null);

  const onGenerateReportClick = async () => {
    setIsLoading(true);
    setData(null);
    try {
      if (!createdAtStart) {
        notification.error({
          message: 'É preciso informar a data inicial.',
          duration: 5
        });
        return;
      }
      setData(
        await SentPushReportService.getReport({
          language: parseEmpty(language),
          subjectId: parseEmpty(subjectId),
          excluded: parseEmpty(excluded),
          createdAtStart: createdAtStart.format(dateFormat),
          createdAtEnd: moment.isMoment(createdAtEnd)
            ? createdAtEnd.format(dateFormat)
            : undefined
        })
      );
    } catch (e: any) {
      notification.open({
        message: e.message,
        description: e.data?.message,
        duration: 0,
        icon: <FrownIcon style={{ color: '#ED0601' }} />
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        margin: 32,
        backgroundColor: 'white'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Button
          icon={<ArrowLeftIcon />}
          size="large"
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>

        <div>
          <Select
            showSearch
            style={{ width: 180 }}
            value={language}
            onChange={(value) => setLanguage(value)}
            placeholder="Idioma"
            allowClear
          >
            {languages.map(({ language: lang, name }: ILanguage) => (
              <Select.Option key={lang} value={lang}>
                {name}
              </Select.Option>
            ))}
          </Select>

          {/* <Select
            showSearch
            style={{ width: 180, marginLeft: 16 }}
            value={subjectId}
            onChange={(value) => setSubjectId(value)}
          >
            <Select.Option value="">Editoria</Select.Option>
            {subjects.map(({ subjectId: id, pt }) => (
              <Select.Option key={id} value={id}>
                {pt}
              </Select.Option>
            ))}
          </Select> */}

          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data inicial"
            value={createdAtStart}
            onChange={(value) => {
              setCreatedAtStart(value);
            }}
          />
          <DatePicker
            style={{ marginLeft: 16 }}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="data final"
            value={createdAtEnd}
            onChange={(value) => setCreatedAtEnd(value)}
          />

          <Select
            style={{ width: 200, marginLeft: 16 }}
            value={excluded}
            defaultActiveFirstOption
            onChange={(value) => setExcluded(value)}
          >
            <Select.Option key="excluded_all" value="">
              Todas as notícias
            </Select.Option>
            <Select.Option key="excluded_true" value={true}>
              Mostrar excluídas
            </Select.Option>
            <Select.Option key="excluded_false" value={false}>
              Mostrar não excluídas
            </Select.Option>
          </Select>
        </div>

        <Button
          type="primary"
          size="large"
          style={{ width: 150 }}
          onClick={onGenerateReportClick}
          disabled={isLoading}
        >
          Gerar relatório
        </Button>
      </div>
      <div style={{ marginTop: 32 }}>
        {isLoading && <Loading />}

        {data && !isLoading && (
          <>
            <h4>
              {!data.length && 'Nenhum regitro encontrado'}
              {data.length === 1 && `${data.length} regitro encontrado`}
              {data.length > 1 &&
                `${data.length
                  .toString()
                  .replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    '.'
                  )} registros encontrados`}
            </h4>
            <Table
              bordered
              dataSource={data}
              style={{ marginTop: 16 }}
              columns={columns}
            />
            <Button style={{ marginTop: 16, marginBottom: 48 }} size="large">
              <CSVLink
                filename="relatorio-pushes-enviados.csv"
                data={data}
                headers={columns.map((item) => ({
                  label: item.title,
                  key: item.dataIndex
                }))}
              >
                Exportar para Excel
              </CSVLink>
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export default SentPushReport;
