import {
  SubjectSponsorListResponse,
  SubjectSponsorCreateRequest,
  SubjectSponsorRemoveRequest,
  SubjectSponsorEditRequest
} from 'models';
import { Network, ServiceError } from './commons';

export const SubjectSponsorService = {
  list: async () => {
    try {
      const { data } = await Network.get<SubjectSponsorListResponse>(
        'admin/subject_sponsor'
      );
      return data?.data;
    } catch (e) {
      throw new ServiceError('subjectSponsor.list', e);
    }
  },
  create: async ({
    subjectId,
    country,
    language,
    sponsorId
  }: SubjectSponsorCreateRequest) => {
    try {
      await Network.post('admin/subject_sponsor', {
        subjectId,
        country,
        language,
        sponsorId
      });
    } catch (e) {
      throw new ServiceError('subjectSponsor.create', e);
    }
  },
  edit: async ({
    subjectSponsorId,
    subjectId,
    country,
    language,
    sponsorId
  }: SubjectSponsorEditRequest) => {
    try {
      await Network.put(`admin/subject_sponsor/${subjectSponsorId}`, {
        subjectId,
        country,
        language,
        sponsorId
      });
    } catch (e) {
      throw new ServiceError('subjectSponsor.edit', e);
    }
  },
  remove: async ({ subjectSponsorId }: SubjectSponsorRemoveRequest) => {
    try {
      await Network.delete(`admin/subject_sponsor/${subjectSponsorId}`);
    } catch (e) {
      throw new ServiceError('subjectSponsor.remove', e);
    }
  }
};
