import React from 'react';
import { observer } from 'mobx-react';
import { NewsEditorStore } from 'stores';
import showSponsoredLang from '../../utils/showSponsoredLang';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const PreviewFooter = observer(({ news }) => {
  const { loading, language, sponsorImageUrl } = NewsEditorStore();

  return !loading ? (
    <footer className="preview--footer">
      {!!sponsorImageUrl && (
        <>
          <p>{showSponsoredLang(language)}</p>
          <br />
          <img src={sponsorImageUrl} alt={showSponsoredLang(language)} />
        </>
      )}
      {!!news.createdBy && (
        <div style={{ textAlign: 'left', marginLeft: 12 }}>
          <p
            style={{ marginTop: 32, color: '#666', fontSize: 12 }}
          >{`Criada por ${news.createdBy} em ${format(
            new Date(news.createdAt),
            'dd/MMM H:mm',
            {
              locale: ptBR
            }
          )}`}</p>
          {!!news.updatedBy && (
            <p
              style={{ marginTop: 6, color: '#666', fontSize: 12 }}
            >{`Alterada por ${news.updatedBy} em ${format(
              new Date(news.updatedAt),
              'dd/MMM H:mm',
              {
                locale: ptBR
              }
            )}`}</p>
          )}
          {!!news.deletedBy && (
            <p
              style={{ marginTop: 6, color: '#666', fontSize: 12 }}
            >{`Excluída por ${news.deletedBy} em ${format(
              new Date(news.deletedAt),
              'dd/MMM H:mm',
              {
                locale: ptBR
              }
            )}`}</p>
          )}
        </div>
      )}
    </footer>
  ) : null;
});

export default PreviewFooter;
