import { makeAutoObservable } from 'mobx';
import { SubjectSponsorService } from 'services';
import type {
  ISubjectSponsor,
  SubjectSponsorCreateRequest,
  SubjectSponsorEditRequest,
  SubjectSponsorRemoveRequest
} from 'models';

class SubjectSponsorStoreMobx {
  subjectSponsors: ISubjectSponsor[] = [];

  initialized = false;

  loading = true;

  error = false;

  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  reset = async () => {
    try {
      this.loading = true;
      this.error = false;

      this.subjectSponsors = await SubjectSponsorService.list();

      this.initialized = true;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.error = true;
      console.log(e);
    }
  };

  create = async (data: SubjectSponsorCreateRequest) => {
    await SubjectSponsorService.create(data);
    await this.reset();
  };

  edit = async (data: SubjectSponsorEditRequest) => {
    await SubjectSponsorService.edit(data);
    await this.reset();
  };

  remove = async ({ subjectSponsorId }: SubjectSponsorRemoveRequest) => {
    await SubjectSponsorService.remove({ subjectSponsorId });
    await this.reset();
  };

  list = async () => {
    try {
      await SubjectSponsorService.list();
    } catch (e) {
      console.error('falha ao listar os patrocinadores de editorias', e);
    }
  };
}

let singleton: SubjectSponsorStoreMobx | null = null;

export const SubjectSponsorStore = () => {
  if (!singleton) {
    singleton = new SubjectSponsorStoreMobx();
  }
  return singleton;
};
