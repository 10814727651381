import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { NewsService, ImageService } from 'services';
import { StaticDataStore } from './staticDataStore';
import { NewsListStore } from './newsListStore';

class NewsEditorStoreMobx {
  newsId = undefined;

  title = '';

  content = '';

  language = '';

  countries = [];

  subjectId = undefined;

  sponsorId = undefined;

  sponsorIdRss = undefined;

  imageUrl = '';

  thumbUrl = '';

  imageDescription = '';

  sourceUrl = '';

  sendPush = true;

  sendPushOnlyTo = undefined;

  sendPushOnlyToStr = '';

  deletedAt = undefined;

  deletedBy = '';

  updatedAt = undefined;

  updatedBy = '';

  lettering = true;

  newsBeforeEdition = undefined;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setEmptyState = () => {
    this.newsId = undefined;
    this.title = '';
    this.content = '';
    this.language = '';
    this.countries = [];
    this.subjectId = undefined;
    this.sponsorId = undefined;
    this.sponsorIdRss = undefined;
    this.imageUrl = '';
    this.thumbUrl = '';
    this.imageDescription = '';
    this.sourceUrl = '';
    this.sendPush = true;
    this.sendPushOnlyTo = undefined;
    this.sendPushOnlyToStr = '';
    this.createdBy = undefined;
    this.deletedAt = undefined;
    this.deletedBy = '';
    this.updatedAt = undefined;
    this.updatedBy = '';
    this.lettering = true;
    this.newsBeforeEdition = undefined;
    // this.sentPushCount = null;
    this.pushCount = null;
    this.shareCount = null;
    this.openedByPush = null;
    this.openedByApp = null;
  };

  startNewNews = () => {
    this.setEmptyState();
  };

  setSelectedNews = async (news) => {
    this.loading = true;
    this.newsId = news.newsId;
    this.createdAt = news.createdAt;
    this.title = news.title;
    this.language = news.language;
    this.countries = news.countries;
    this.subjectId = news.subjectId;
    this.thumbUrl = news.thumbUrl;
    this.sendPush = false;
    this.lettering = false;
    this.createdBy = undefined;
    this.deletedAt = undefined;
    this.deletedBy = undefined;
    this.updatedAt = undefined;
    this.updatedBy = undefined;

    const { data } = await NewsService.show(this.newsId);

    runInAction(() => {
      this.content = data.content;
      this.sponsorId = data.sponsorId;
      this.sponsorIdRss = data.sponsorIdRss;
      this.imageUrl = data.imageUrl;
      this.imageDescription = data.imageDescription;
      this.sourceUrl = data.sourceUrl;
      this.sendPush = data.sendPush;
      this.lettering = !!data.lettering;
      this.sendPushOnlyTo = data.sendPushOnlyTo;
      this.sendPushOnlyToStr = data.sendPushOnlyTo?.length
        ? data.sendPushOnlyTo.join(', ')
        : '';
      this.createdBy = data.createdBy;
      this.deletedAt = data.deletedAt;
      this.deletedBy = data.deletedBy;
      this.updatedAt = data.updatedAt;
      this.updatedBy = data.updatedBy;

      this.newsBeforeEdition = {
        ...news,
        content: data.content,
        sponsorId: data.sponsorId,
        sponsorIdRss: data.sponsorIdRss,
        imageUrl: data.imageUrl,
        imageDescription: data.imageDescription,
        sourceUrl: data.sourceUrl,
        sendPushOnlyTo: data.sendPushOnlyTo,
        sendPushOnlyToStr: data.sendPushOnlyToStr,
        sendPush: data.sendPush,
        lettering: !!data.lettering
      };
      this.pushCount = data.pushCount;
      // this.sentPushCount = data.sentPushCount;
      this.openedByPush = data.openedByPush;
      this.openedByApp = data.openedByApp;
      this.shareCount = data.shareCount;
      this.loading = false;
    });
  };

  cancelEditMode = () => {
    if (this.newsBeforeEdition) {
      this.setSelectedNews(toJS(this.newsBeforeEdition));
    } else {
      this.setEmptyState();
    }
  };

  setTitle = (value) => {
    this.title = value || '';
  };

  setContent = (value) => {
    this.content = value || '';
  };

  setLanguage = (value) => {
    this.language = value;
  };

  setCountries = (value) => {
    this.countries = value;
  };

  setSubjectId = (subjectId) => {
    this.subjectId = subjectId;
  };

  setSponsorId = (sponsorId) => {
    this.sponsorId = sponsorId;
  };

  setSponsorIdRss = (sponsorIdRss) => {
    this.sponsorIdRss = sponsorIdRss;
  };

  setSourceUrl = (value) => {
    this.sourceUrl = (value || '').trim();
  };

  setSendPush = (value) => {
    this.sendPush = value;
  };

  setSendPushOnlyTo = (value) => {
    this.sendPushOnlyToStr = value;
    const preparedStr = value.replaceAll(' ', '').trim();
    this.sendPushOnlyTo = value ? preparedStr.split(',') : undefined;
  };

  setLettering = (value) => {
    this.lettering = value;

    if (!value) {
      this.sponsorIdRss = undefined;
    }
  };

  get languageName() {
    if (!this.language) {
      return '';
    }
    return StaticDataStore().getLanguageName(this.language);
  }

  get subjectName() {
    if (!this.subjectId) {
      return '';
    }
    return StaticDataStore().getSubjectName(
      this.subjectId,
      this.language || 'pt' // default pt qdo informa assunto antes do idioma
    );
  }

  get subjectNamePt() {
    if (!this.subjectId) {
      return '';
    }
    return StaticDataStore().getSubjectName(this.subjectId, 'pt');
  }

  get sponsorData() {
    if (!this.sponsorId) {
      return {};
    }
    return StaticDataStore().getSponsorData(this.sponsorId);
  }

  get sponsorRssData() {
    if (!this.sponsorIdRss) {
      return {};
    }
    return StaticDataStore().getSponsorData(this.sponsorIdRss);
  }

  get sponsorImageUrl() {
    return this.sponsorData?.imageUrl;
  }

  save = async () => {
    const data = {
      title: this.title,
      content: this.content,
      language: this.language,
      countries: this.countries,
      subjectId: this.subjectId,
      sponsorId: this.sponsorId,
      sponsorIdRss: this.sponsorIdRss,
      imageUrl: this.imageUrl,
      thumbUrl: this.thumbUrl,
      imageDescription: this.imageDescription,
      sourceUrl: this.sourceUrl,
      lettering: this.lettering
    };

    if (!this.newsId) {
      data.sendPush = this.sendPush;
      data.sendPushOnlyTo = this.sendPushOnlyTo;

      await NewsService.create(data);
    } else {
      await NewsService.replace(this.newsId, data);
    }

    NewsListStore().reset();
  };

  deleteSelectedNews = async () => {
    if (!this.newsId) {
      throw new Error('Nenhuma notícia selecionada');
    }
    await NewsService.remove(this.newsId);
    this.setEmptyState();
    await NewsListStore().reset();
  };

  getLatestImages = async () => {
    return NewsService.getLatestImages();
  };

  getPushCount = async () => {
    return NewsService.getPushCount({
      subjectId: this.subjectId,
      language: this.language,
      countries: this.countries,
      sendPushOnlyTo: this.sendPushOnlyTo
    });
  };

  setImage = (thumbUrl, imageUrl, description) => {
    this.imageUrl = imageUrl;
    this.thumbUrl = thumbUrl;
    this.imageDescription = description;
  };

  uploadAndSetImage = async (croppedImage, fullImage, description) => {
    const { croppedImageUrl, fullImageUrl } = await ImageService.uploadImages(
      croppedImage,
      fullImage
    );
    this.setImage(croppedImageUrl, fullImageUrl, description);
  };
}

let singleton = null;

export const NewsEditorStore = () => {
  if (!singleton) {
    singleton = new NewsEditorStoreMobx();
  }
  return singleton;
};
