import React from 'react';

import { Modal, Button } from 'antd';

import { NewsEditorStore, StaticDataStore } from 'stores';

const AddChannelModal = ({ visible, onCancel, onOk }) => {
  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Selecione um assunto"
      onCancel={onCancel}
      width={800}
      footer={null}
      forceRender
    >
      {StaticDataStore().subjects.map(({ subjectId, pt }) => (
        <Button
          key={subjectId}
          type={
            NewsEditorStore().subjectId === subjectId ? 'primary' : 'default'
          }
          size="large"
          style={{ width: 230, marginRight: 20, marginBottom: 20 }}
          onClick={() => {
            NewsEditorStore().setSubjectId(subjectId);
            onOk();
          }}
        >
          {pt}
        </Button>
      ))}
    </Modal>
  );
};

export default AddChannelModal;
