import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';

import { v4 } from 'uuid';
import { Button, Input } from 'antd';
import { UploadOutlined as UploadIcon } from '@ant-design/icons';
import Loading from 'components/Loading';
import convertBase64toFile from 'utils/convertBase64toFile';
import { NewsEditorStore } from 'stores';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import './styles.css';

const UploadImage = ({ onCancel }) => {
  const [image, setImage] = useState();
  const [imageCrop, setImageCrop] = useState();
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const cropperRef = useRef(null);

  const handleUploadImages = async (event) => {
    event.preventDefault();

    const thumbCrop = convertBase64toFile(imageCrop, `${v4()}.png`);
    const thumbFull = convertBase64toFile(image, `${v4()}.jpg`);

    try {
      setLoading(true);

      await NewsEditorStore().uploadAndSetImage(
        thumbCrop,
        thumbFull,
        description
      );

      setLoading(false);
      onCancel();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onChangeInputFile = (e) => {
    e.preventDefault();

    let files;
    const reader = new FileReader();

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    setImageCrop(
      cropper.getCroppedCanvas({ width: 200, height: 260 }).toDataURL()
    );
  };

  const handleClearSelection = () => {
    setDescription('');
    setImage('');
  };

  return loading ? (
    <Loading />
  ) : (
    <form className="formImage" onSubmit={handleUploadImages} noValidate>
      {image ? (
        <>
          <Cropper
            src={image}
            className="imageCropper"
            background={false}
            guides
            crop={onCrop}
            ref={cropperRef}
            zoomable={false}
            viewMode={0}
            initialAspectRatio={20 / 26}
            autoCropArea={0.5}
          />

          <div className="description--wrapper">
            <Input
              type="text"
              placeholder="Insira uma descrição para a imagem"
              value={description}
              maxLength={1000}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="imageButton--wrapper">
            <Button size="large" onClick={() => handleClearSelection()}>
              Limpar seleção
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              Confirmar
            </Button>
          </div>
        </>
      ) : (
        <div className="imagePlaceholder--wrapper">
          <UploadIcon className="iconUpload" />

          <label htmlFor="fileUpload" className="fileUpload--label">
            <input
              id="fileUpload"
              className="fileUpload--input"
              type="file"
              onChange={onChangeInputFile}
            />
            Imagem no formato png ou jpg
          </label>
        </div>
      )}
    </form>
  );
};

export default UploadImage;
