import React from 'react';
import { observer } from 'mobx-react';

import { Modal, Button } from 'antd';

import { NewsEditorStore, StaticDataStore } from 'stores';

const AddLangModal = observer(({ visible, onCancel, onOk }) => {
  const { languages } = StaticDataStore();

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Selecione um idioma"
      onCancel={onCancel}
      width={800}
      footer={null}
      forceRender
    >
      {languages.map(({ language: lang, name }) => (
        <Button
          key={lang}
          type={NewsEditorStore().language === lang ? 'primary' : 'default'}
          size="large"
          style={{ width: 130, marginRight: 20 }}
          onClick={() => {
            NewsEditorStore().setLanguage(lang);
            onOk();
          }}
        >
          {name}
        </Button>
      ))}
    </Modal>
  );
});

export default AddLangModal;
